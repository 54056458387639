import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ApprovalStatusButton
} from "components"
import { DateConvert, PageNumber as TableNumber } from "utilities"
import { ComplimentApi } from "api"
import { AiOutlineEye } from "react-icons/ai"

const HistoryApprovalCompliment = () => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataCompliment, setDataCompliment] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    ComplimentApi.getHistory({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        const complimentData = data?.data
        setDataCompliment(complimentData ? complimentData : [])
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data?.data_count,
          totalPage: data?.total_page,
        })
        searchConfig.status &&
          setAlertConfig({
            show: true,
            variant: "primary",
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }
  const generateStatus = (status) => {
    if (status.toUpperCase() === "REV") {
      return "REVISI"
    }

    if (status.toUpperCase() === "APP") {
      return "APPROVED"
    }

    if (status.toUpperCase() === "VER") {
      return "VERIFIED"
    }

    return "PENDING"
  }

  useEffect(() => {
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [searchConfig.key, paginationConfig.page, paginationConfig.dataLength])

  const PageContent = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : ""
      const convert = data.substring(0, 3)

      if (convert === "APP")
        return {
          variant: "outline-success",
          label: "APPROVED",
        }
      if (convert === "VER")
        return {
          variant: "outline-green-light",
          label: "VERIFIED",
        }
      if (convert === "REV")
        return {
          variant: "outline-warning",
          label: "REVISION",
        }
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECTED",
        }
      return {
        variant: "outline-secondary",
        label: "PENDING",
      }
    }
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false)
  
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return <li key={index} index={index}>{`${brg.nama_item} ${brg.qty_compliment ? parseFloat(brg.qty_compliment) : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>
              } else {
                return index <= 1 && <li key={index} index={index}>{`${brg.nama_item} ${brg.qty_compliment ? parseFloat(brg.qty_compliment) : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>
              }
            })}
          </ul>
          {data.length > 2 ? (
            <div
              className="text-primary"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                // fontWeight: "bold",
              }}
              onClick={() => { setisReadMoreClick((prev) => !prev)}}>
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          ): ("")}
        </>
      )
    }
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th style={{minWidth:"230px"}}>Informasi Pembuat Pengajuan</Th>
            <ThFixed>Informasi Compliment</ThFixed>
            <Th style={{minWidth:"230px"}}>Customer</Th>
            <Th style={{minWidth:"230px"}}>Petugas Pemberi Compliment</Th>
            <Th style={{minWidth:"300px"}}>Item Barang</Th>
            <Th style={{minWidth:"230px"}}>Keterangan</Th>
            <ThFixed>Status Approval</ThFixed>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataCompliment.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</TdFixed>
              <Td>
                <div className="text-left">{val.nama_karyawan_pengaju ? val.nama_karyawan_pengaju : "-"}</div>
                <div className="text-left">{val.nama_jabatan_pengaju ? val.nama_jabatan_pengaju : "-"}</div>
              </Td>
              <TdFixed>
                <div className="text-left">{val.tgl_compliment ? DateConvert(new Date(val.tgl_compliment)).custom : "-"}</div>
                <div className="text-left">{val.no_compliment ? val.no_compliment : "-"}</div>
              </TdFixed>
              <Td>{val.nama_customer ? val.nama_customer : "-"}</Td>
              <Td>{val.nama_petugas ? val.nama_petugas : "-"}</Td>
              <Td>{val.item_barang ? val.item_barang.length > 0 ? <ItemBarangCollapse data={val.item_barang} /> : "-" : "-"}</Td>
              <Td>{val.keterangan ?? "-"}</Td>
              <TdFixed>
                <ApprovalStatusButton variant={checkStatus(val.status_approval).variant}>
                  {checkStatus(val.status_approval).label}
                </ApprovalStatusButton>
              </TdFixed>
              <Td>
                <div className="d-flex justify-content-center">
                  <ActionButton
                    size="sm"
                    text={<AiOutlineEye className="text-dark" style={{fontSize:"16px"}} />}
                    onClick={() => history.push(`/human-resource/approval/compliment/detail/${val.id_compliment}`, { no_compliment: val.no_compliment, tab: 'history' })}
                  />
                </div>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    )

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={(e) => {
                  const key = e.target.value
                  setSearchConfig({
                    ...searchConfig,
                    key: e.target.value,
                  })
                  setAlertConfig({
                    show: key ? true : false,
                    variant: "primary",
                    text: "Hasil dari pencarian: " + key,
                  })
                }}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading 
        ? <DataStatus loading={true} text="Memuat data . . ." />
        : dataCompliment 
          ? dataCompliment.length > 0 
            ? <PageContent />
            : <DataStatus text="Tidak ada data" />
          : <DataStatus text="Data gagal dimuat" />
      }
    </CRUDLayout>
  )
}

export default HistoryApprovalCompliment