import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import * as Yup from "yup";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { Formik } from "formik";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { Alert, DataStatus, BackButton, ActionButton, TextEditor, TextArea } from "components";
import { RupiahConvert, DateConvert } from "utilities";
import { PenawaranApi } from "api";
import { TableLC, TableMP } from "./TablePenawaran";
import AnalisaBarangJadiPenawaran from "./AnalisaBarangJadiPenawaran";

const DetailPenawaran = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { id_penawaran } = useParams();
  const { no_penawaran, baseline, tab } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataListPenawaran, setDataListPenawaran] = useState([]);
  const [dataListLC, setDataListLC] = useState([]);
  const [dataListMP, setDataListMP] = useState([]);

  const [dataPenawaran, setDataPenawaran] = useState({});
  const [dataApproval, setDataApproval] = useState([]);

  const [totalPenawaran, setTotalPenawaran] = useState(0);
  const [totalLC, setTotalLC] = useState(0);
  const [totalMP, setTotalMP] = useState(0);

  const [approveStatus, setApproveStatus] = useState("V");
  const [processedData, setProcessedData] = useState({});
  const [modalAnalisaConfig, setModalAnalisaConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  // STATE TEXT EDITOR PEMBUKA
  const [textEditorState, setTextEditorState] = useState(() => {
    EditorState.createEmpty();
  });
  // STATE TEXT EDITOR PENUTUP
  const [textEditorStatePenutup, setTextEditorStatePenutup] = useState(() => {
    EditorState.createEmpty();
  });

  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle",
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    //GET SINGLE DETAIL PENAWARAN
    Axios.all([PenawaranApi.getSingle({ no_penawaran, baseline })])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data;
          const behavior = data.data.data.behavior ? data.data.data.behavior : "V";

          setDataPenawaran(detail);
          setDataApproval(approval);

          setDataListPenawaran(detail.detail_barang_jadi);
          setDataListLC(detail.detail_upah);
          setDataListMP(detail.detail_alat_mesin);

          setApproveStatus(behavior.toUpperCase());

          // SET TEXT EDIOR
          setTextEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(detail?.teks_pembuka ?? "<p></p>"))
            )
          );
          setTextEditorStatePenutup(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(detail?.teks_penutup ?? "<p></p>"))
            )
          );
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return "-";
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Penawaran"
                value={
                  dataPenawaran?.tgl_penawaran
                    ? getConvertedDate(dataPenawaran?.tgl_penawaran)
                    : "-"
                }
              />
              <InfoItem title="No. Penawaran" value={dataPenawaran?.no_penawaran ?? "-"} />
              <InfoItem
                title="Tgl. RAB"
                value={dataPenawaran?.tgl_rab ? getConvertedDate(dataPenawaran?.tgl_rab) : "-"}
              />
              <InfoItem title="No. RAB" value={dataPenawaran?.no_rab ?? "-"} />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Customer"
                value={dataPenawaran.nama_customer ? dataPenawaran.nama_customer : "-"}
              />
              <InfoItem title="ATT" value={dataPenawaran.att ? dataPenawaran.att : "-"} />
              <InfoItem
                title="Tanggal Selesai"
                value={
                  dataPenawaran.tgl_selesai
                    ? DateConvert(new Date(dataPenawaran.tgl_selesai)).detail
                    : "-"
                }
              />
              <InfoItem
                title="Peringkat Peluang"
                value={
                  dataPenawaran?.peringkat_peluang?.nama_peringkat_peluang
                    ? dataPenawaran?.peringkat_peluang?.nama_peringkat_peluang
                    : "-"
                }
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const GrandTotalSection = ({ totalPenawaran, totalLC, totalMP }) => {
    const hitungGrandTotal = () => {
      let grandTotal = totalPenawaran + totalLC + totalMP;
      return RupiahConvert(String(grandTotal)).detail;
    };

    useEffect(() => {
      hitungGrandTotal();
    }, [totalPenawaran, totalLC, totalMP]);

    return (
      <div className="p-2 bg-light text-right border">
        <b className="text-nowrap pr-3" style={{ fontSize: "14px" }}>
          Grand Total : {hitungGrandTotal()}
        </b>
      </div>
    );
  };

  const TablePenawaran = ({ dataListPenawaran }) => {
    // FUNGSI UNTUK HITUNG SUBTOTAL
    const hitungSubTotal = (index) => {
      let rounded = dataListPenawaran[index].rounded ?? 0;
      let qty = dataListPenawaran[index].qty_rae;
      let subTotal = rounded * qty;

      // SET HASIL SUBTOTAL KE DALAM OBJECT
      dataListPenawaran[index].subTotal = subTotal;
      return RupiahConvert(String(subTotal)).detail;
    };

    // FUNGSI HITUNG JUMLAH KESELURUHAN SUBTOTAL
    const hitungTotalPenawaran = () => {
      let totalPenawaran = dataListPenawaran.reduce(function (accumulator, { subTotal }) {
        return accumulator + subTotal;
      }, 0);

      let ppn = dataPenawaran?.ppn ?? 0;

      let totalPenawaranPPN = totalPenawaran + (totalPenawaran * ppn) / 100;
      totalPenawaranPPN = parseInt(totalPenawaranPPN);

      // SET TOTAL SETELAH PAJAK
      setTotalPenawaran(totalPenawaranPPN);

      return {
        totalPenawaran,
        totalPenawaranPPN,
      };
    };

    return (
      <>
        <div className="p-2 mt-3">
          <b>List Item Penawaran</b>
        </div>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        <div>
          <table className="table table-bordered bg-white table-sm">
            <thead className="text-center bg-light">
              <tr key="head1">
                <th rowSpan={2} className="align-middle" style={tableStyling}>
                  No.
                </th>
                <th rowSpan={2} className="align-middle" style={{ ...tableStyling, width: "20px" }}>
                  Kode Item
                </th>
                <th rowSpan={2} className="align-middle" style={tableStyling}>
                  Nama Item
                </th>
                <th colSpan={2} className="align-middle" style={tableStyling}>
                  Volume
                </th>
                <th rowSpan={2} className="align-middle" style={tableStyling}>
                  Rounded
                </th>
                <th
                  rowSpan={2}
                  className="align-middle"
                  style={{ ...tableStyling, width: "121px" }}
                >
                  Sub Total (Rp)
                </th>
              </tr>
              <tr key="head2">
                <th style={tableStyling}>Qty</th>
                <th style={tableStyling}>Unit</th>
              </tr>
            </thead>
            <tbody>
              {dataListPenawaran &&
                dataListPenawaran.length > 0 &&
                dataListPenawaran.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td style={tableStyling} className="px-2">
                        {val.kode_item}
                      </td>
                      <td style={tableStyling} className="px-2">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setProcessedData({
                              ...val,
                              index: index,
                            });
                            setModalAnalisaConfig({
                              show: true,
                              type: "analisa",
                              title: (
                                <span className="text-primary">Analisa Barang Jadi Penawaran</span>
                              ),
                            });
                          }}
                        >
                          {val.nama_item}
                        </a>
                      </td>
                      <td style={tableStyling} className="text-right">
                        {val.qty_rae}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.nama_satuan}
                      </td>
                      <td style={tableStyling} className="text-right">
                        {RupiahConvert(String(val.rounded)).detail}
                      </td>
                      <td style={tableStyling} className="text-right">
                        {hitungSubTotal(index)}
                      </td>
                    </tr>
                  );
                })}

              <tr>
                <td colSpan={6} className="text-right p-2" style={tableStyling}>
                  <b>Total: </b>
                </td>
                <td className="text-right text-nowrap" style={tableStyling}>
                  <b>{RupiahConvert(String(hitungTotalPenawaran().totalPenawaran)).detail}</b>
                </td>
              </tr>
              <tr>
                <td colSpan={6} className="text-right p-2" style={tableStyling}>
                  <b>PPN (%) </b>
                </td>
                <td className="text-right text-nowrap" style={tableStyling}>
                  <b>{parseInt(dataPenawaran?.ppn ?? 0)}</b>
                </td>
              </tr>
              <tr className="bg-light">
                <td colSpan={6} className="text-right p-2" style={tableStyling}>
                  <b>Total Setelah PPN: </b>
                </td>
                <td className="text-right text-nowrap" style={tableStyling}>
                  <b>{RupiahConvert(String(hitungTotalPenawaran().totalPenawaranPPN)).detail}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "APP"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                  ? "Reject"
                  : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                  ? "warning"
                  : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ values, handleChange, dirty, validateForm, errors }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Penawaran</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == "VER" ? `PEMERIKSA ${val.approval_level}` : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          <div>
            <TextArea
              label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject Penawaran</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: <span className="text-success">Approve Penawaran</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject Penawaran</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REV",
                          title: <span className="text-warning">Revise Penawaran</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: <span className="text-success">Verify Penawaran</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const CatatanApproval = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Penawaran</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPenawaran?.stakeholder.map(
              (val, index) =>
                index !== 0 &&
                val.status_approval !== "PEN" && (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === "VER" || val.status_approval === "REV"
                          ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                          : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                      }
                      value1={val.nama_karyawan ?? "-"}
                      title2="Catatan"
                      value2={val.catatan ?? "-"}
                    />
                  </Col>
                )
            )}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const ModalAnalisaSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiPenawaran
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === "analisa" ? "xl" : "md"}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  useEffect(() => {
    setNavbarTitle("Penawaran");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Penawaran</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
            </>
          )}

          {/* Text Editor Pembuka */}
          {!isPageLoading && (
            <div className="mt-3">
              <TextEditor
                readOnly
                options={[]}
                label="Teks Pembuka"
                editorState={textEditorState}
              />
            </div>
          )}

          {!isPageLoading && <TablePenawaran dataListPenawaran={dataListPenawaran} />}

          {!isPageLoading && (
            <TableLC dataListLC={dataListLC} totalLC={totalLC} setTotalLC={setTotalLC} />
          )}

          {!isPageLoading && (
            <TableMP dataListMP={dataListMP} totalMP={totalMP} setTotalMP={setTotalMP} />
          )}

          {!isPageLoading && (
            <GrandTotalSection
              totalPenawaran={totalPenawaran}
              totalLC={totalLC}
              totalMP={totalMP}
            />
          )}

          {/* Text Editor Penutup */}

          {!isPageLoading && (
            <div className="mt-3">
              <TextEditor
                readOnly
                options={[]}
                label="Teks Penutup"
                editorState={textEditorStatePenutup}
              />
            </div>
          )}
        </Card.Body>
      </Card>

      {console.log(dataPenawaran)}
      {!isPageLoading && tab !== "history" && (
        <Formik
          initialValues={{ catatan: "" }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan Catatan"),
          })}
          onSubmit={(values) => {
            const finalValues = {
              ...values,
              no_transaksi: dataPenawaran.no_penawaran,
              status_approval: modalConfig.type,
              tgl_approval: DateConvert(new Date()).default,
              approval_baseline: dataPenawaran.baseline,
            };

            //   console.log("simpan", finalValues);

            PenawaranApi.approve(finalValues)
              .then((res) => {
                history.push("/human-resource/approval/penawaran", {
                  alert: {
                    show: true,
                    variant: "primary",
                    text: `${modalConfig.type == "APP"
                      ? "Approve"
                      : modalConfig.type == "REV"
                        ? "Revise"
                        : "Reject"
                      } data berhasil!`,
                  },
                });
                const logValues = {
                  no_transaksi: dataPenawaran.no_penawaran,
                  nama_transaksi: "Peluang",
                  baseline: dataPenawaran.baseline,
                  status_approval: modalConfig.type,
                  no_transaksi_ref: dataPenawaran.no_rab,
                  baseline_ref: dataPenawaran.baseline,
                  keterangan_transaksi:
                    modalConfig.type === "APP"
                      ? "Approve Penawaran"
                      : modalConfig.type === "VER"
                        ? "Verifikasi Penawaran"
                        : "Reject Penawaran",
                };
                PenawaranApi.saveLogProgress(logValues);
              })
              .catch((err) => {
                setAlertConfig({
                  variant: "danger",
                  text: `Ubah data gagal! (${err.response.data.message})`,
                });
              })
              .finally(() => setModalConfig({ show: false }));
          }}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            validateForm,
            dirty,
            errors,
            touched,
          }) => (
            <>
              <FormCard
                values={values}
                handleChange={handleChange}
                dirty={dirty}
                validateForm={validateForm}
                errors={errors}
                touched={touched}
              />
              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      )}

      {!isPageLoading && tab === "history" && <CatatanApproval />}

      <ModalAnalisaSection
        processedData={processedData}
        dataBarangJadi={dataListPenawaran}
        setDataBarangJadi={setDataListPenawaran}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        modalConfig={modalAnalisaConfig}
        setModalConfig={setModalAnalisaConfig}
      />
    </>
  );
};

export default DetailPenawaran;
