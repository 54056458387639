// Component
import { Col, Row } from "react-bootstrap"
import { DateConvert } from "utilities"

const ListData = ({ title, text }) => (
	<div className='mb-2'>
		<small style={{ fontSize: 12 }} className="text-secondary">{title}</small>
		<div style={{ fontSize: 14 }}>{text ?? '-'}</div>
	</div>
)

const InfoSJSection = ({ data }) => (
	<>
		<Row>
			<Col lg={3}>
				<ListData 
					title="Tgl. Surat Jalan"
					text={data?.tgl_surat_jalan ? DateConvert(new Date(data.tgl_surat_jalan)).detail : "-"}
				/>
			</Col>
            <Col lg={3}>
				<ListData 
					title="No. Surat Jalan"
					text={data?.no_surat_jalan}
				/>
            </Col>
			<Col lg={3}>
				<ListData 
					title="Petugas Pengiriman"
					text={data?.nama_sopir}
				/>
			</Col>
            <Col lg={3}>
				<ListData 
					title="Armada Pengiriman"
					text={data?.nama_item_aset}
				/>
            </Col>
		</Row>
        <Row>
            <Col lg={12}>
                <ListData 
					title="Jenis Surat jalan"
					text={data?.is_konsinyasi ? `Konsinyasi | ${data?.nama_gudang ?? "-"}` : "Penjualan"}
				/>
            </Col>
        </Row>
	</>
)

export default InfoSJSection