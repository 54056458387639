import { IoCheckboxOutline, IoSpeedometerOutline, IoPersonCircleOutline } from "react-icons/io5";
import Logo from "../assets/image/logo_khrisna.png";
import Profil from "../pages/Profil";
import {
  PurchaseRequest,
  MainApprovalRAP,
  DetailRAP,
  MainApprovalSPK,
  DetailApprovalSPK,
  MainApprovalDeliveryOrder,
  DetailApprovalDeliveryOrder,
  MainApprovalFakturPenjualan,
  DetailFakturPenjualan,
  MainApprovalKegiatan,
  DetailKegiatan,
  MainApprovalMutasiAntarGudang,
  DetailMutasiAntarGudang,
  MainApprovalPenerimaanBarang,
  DetailPenerimaanBarang,
  MainApprovalPenerimaanFaktur,
  DetailApprovalPenerimaanFaktur,
  MainApprovalPPA,
  DetailPPA,
  MainApprovalPurchaseOrder,
  DetailApprovalPurchaseOrder,
  MainApprovalPurchaseRequest,
  DetailPurchaseRequest,
  MainApprovalSalesOrder,
  DetailSalesOrder,
  MainApprovalSeleksiVendor,
  DetailApprovalSeleksiVendor,
  MainApprovalSuratJalan,
  DetailSuratJalan,
  MainApprovalPenawaran,
  DetailPenawaran,
  MainApprovalRAB,
  DetailRAB,
  MainApprovalRAE,
  DetailRAE,
  MainApprovalMutasiBarangJadi,
  DetailMutasiBarangJadi,
  MainApprovalPeluang,
  DetailPeluang,
  MainApprovalCompliment,
  DetailCompliment,
} from "../pages/Approval";

export default {
  LOGO: Logo,
  MODUL: "HRD User",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ["HRDU"],
    },
    {
      text: "Approval",
      type: "dropdown",
      icon: <IoCheckboxOutline />,
      hak: [
        "HRDU",
        "HRDU_APR_KEG",
        "HRDU_APR_PR",
        "HRDU_APR_REGRAB",
        "HRDU_APR_REGPNW",
        "HRDU_APR_PVSPR",
        "HRDU_APR_REGPRO",
        "HRDU_APR_PENPO",
        "HRDU_APR_REGFPNJ",
        "HRDU_APR_PENFPNJ",
        "HRDU_APR_REGDO",
        "HRDU_APR_REGPO",
        "HRDU_APR_REGRAE",
        "HRDU_APR_REGRAP",
        "HRDU_APR_REGSJ",
        "HRDU_APR_REGSO",
        "HRDU_APR_REGSPK",
        "HRDU_APR_RK",
        "HRDU_APR_REGPPA",
        "HRDU_APR_MAG",
        "HRDU_APR_MBJ",
        "HRDU_APR_REGPLG",
        "HRDU_APR_CMP",
      ],
      menu: [
        {
          text: "Kegiatan",
          link: "/human-resource/approval/kegiatan",
          hak: ["HRDU", "HRDU_APR_KEG", "HRDU_APR_RK"],
        },
        {
          text: "PPA",
          link: "/human-resource/approval/ppa",
          hak: ["HRDU", "HRDU_APR_REGPPA"],
        },
        {
          text: "RAE",
          link: "/human-resource/approval/rae",
          hak: ["HRDU", "HRDU_APR_REGRAE"],
        },
        {
          text: "RAB",
          link: "/human-resource/approval/rab",
          hak: ["HRDU", "HRDU_APR_REGRAB"],
        },
        {
          text: "RAP",
          link: "/human-resource/approval/rap",
          hak: ["HRDU", "HRDU_APR_REGRAP"],
        },
        {
          text: "Penawaran",
          link: "/human-resource/approval/penawaran",
          hak: ["HRDU", "HRDU_APR_REGPNW"],
        },
        {
          text: "Purchase Order",
          link: "/human-resource/approval/purchase-order",
          hak: ["HRDU", "HRDU_APR_REGPO"],
        },
        {
          text: "Purchase Request (PR)",
          link: "/human-resource/approval/purchase-request",
          hak: ["HRDU", "HRDU_APR_PR"],
        },
        {
          text: "Penerimaan Barang",
          link: "/human-resource/approval/penerimaan-barang",
          hak: ["HRDU", "HRDU_APR_PENPO"],
        },
        {
          text: "Seleksi Vendor",
          link: "/human-resource/approval/seleksi-vendor",
          hak: ["HRDU", "HRDU_APR_PVSPR"],
        },
        {
          text: "Sales Order",
          link: "/human-resource/approval/sales-order",
          hak: ["HRDU", "HRDU_APR_REGSO"],
        },
        {
          text: "Surat Perjanjian Kerja",
          link: "/human-resource/approval/surat-perjanjian-kerja",
          hak: ["HRDU", "HRDU_APR_REGSPK"],
        },
        {
          text: "Faktur Penjualan",
          link: "/human-resource/approval/faktur-penjualan",
          hak: ["HRDU", "HRDU_APR_REGFPNJ"],
        },
        // {
        //   text: "Penerimaan Faktur",
        //   link: "/human-resource/approval/penerimaan-faktur",
        //   hak: ["HRDU", "HRDU_APR_PENFPNJ"],
        // },
        {
          text: "Delivery Order",
          link: "/human-resource/approval/delivery-order",
          hak: ["HRDU", "HRDU_APR_REGDO"],
        },
        {
          text: "Surat Jalan",
          link: "/human-resource/approval/surat-jalan",
          hak: ["HRDU", "HRDU_APR_REGSJ"],
        },
        {
          text: "Mutasi Antar Gudang",
          link: "/human-resource/approval/mutasi-antar-gudang",
          hak: ["HRDU", "HRDU_APR_MAG"],
        },
        {
          text: "Mutasi Barang Jadi",
          link: "/human-resource/approval/mutasi-barang-jadi",
          hak: ["HRDU", "HRDU_APR_MBJ"],
        },
        {
          text: "Peluang",
          link: "/human-resource/approval/peluang",
          hak: ["HRDU", "HRDU_APR_REGPLG"],
        },
        {
          text: "Compliment",
          link: "/human-resource/approval/compliment",
          hak: ["HRDU", "HRDU_APR_CMP"],
        },
      ],
    },
    {
      text: "Profil",
      type: "dropdown",
      icon: <IoPersonCircleOutline />,
      hak: [
        "HRDU",
        "ASM",
        "MKT",
        "CRM",
        "INV",
        "ANG",
        "HRDA",
        "HRDU_APR_KEG",
        "HRDU_APR_PR",
        "HRDU_APR_REGRAB",
        "HRDU_APR_REGPNW",
        "HRDU_APR_PVSPR",
        "HRDU_APR_REGPRO",
        "HRDU_APR_PENPO",
        "HRDU_APR_REGFPNJ",
        "HRDU_APR_PENFPNJ",
        "HRDU_APR_REGDO",
        "HRDU_APR_REGPO",
        "HRDU_APR_REGRAE",
        "HRDU_APR_REGRAP",
        "HRDU_APR_REGSJ",
        "HRDU_APR_REGSO",
        "HRDU_APR_REGSPK",
        "HRDU_APR_RK",
        "HRDU_APR_REGPPA",
        "HRDU_APR_MAG",
        "HRDU_APR_MBJ",
        "HRDU_APR_REGPLG",
      ],
      menu: [
        {
          text: "Akun Saya",
          link: "/profil",
          hak: [
            "HRDU",
            "ASM",
            "MKT",
            "CRM",
            "INV",
            "ANG",
            "HRDA",
            "HRDU_APR_KEG",
            "HRDU_APR_PR",
            "HRDU_APR_REGRAB",
            "HRDU_APR_REGPNW",
            "HRDU_APR_PVSPR",
            "HRDU_APR_REGPRO",
            "HRDU_APR_PENPO",
            "HRDU_APR_REGFPNJ",
            "HRDU_APR_PENFPNJ",
            "HRDU_APR_REGDO",
            "HRDU_APR_REGPO",
            "HRDU_APR_REGRAE",
            "HRDU_APR_REGRAP",
            "HRDU_APR_REGSJ",
            "HRDU_APR_REGSO",
            "HRDU_APR_REGSPK",
            "HRDU_APR_RK",
            "HRDU_APR_REGPPA",
            "HRDU_APR_MAG",
            "HRDU_APR_MBJ",
            "HRDU_APR_REGPLG",
          ],
        },
        {
          text: "Ubah Akun",
          link: "/ubah-profil",
          hak: [
            "HRDU",
            "ASM",
            "MKT",
            "CRM",
            "INV",
            "ANG",
            "HRDA",
            "HRDU_APR_KEG",
            "HRDU_APR_PR",
            "HRDU_APR_REGRAB",
            "HRDU_APR_REGPNW",
            "HRDU_APR_PVSPR",
            "HRDU_APR_REGPRO",
            "HRDU_APR_PENPO",
            "HRDU_APR_REGFPNJ",
            "HRDU_APR_PENFPNJ",
            "HRDU_APR_REGDO",
            "HRDU_APR_REGPO",
            "HRDU_APR_REGRAE",
            "HRDU_APR_REGRAP",
            "HRDU_APR_REGSJ",
            "HRDU_APR_REGSO",
            "HRDU_APR_REGSPK",
            "HRDU_APR_RK",
            "HRDU_APR_REGPPA",
            "HRDU_APR_MAG",
            "HRDU_APR_MBJ",
            "HRDU_APR_REGPLG",
          ],
        },
        {
          text: "Ganti Password",
          link: "/ganti-password",
          hak: [
            "HRDU",
            "ASM",
            "MKT",
            "CRM",
            "INV",
            "ANG",
            "HRDA",
            "HRDU_APR_KEG",
            "HRDU_APR_PR",
            "HRDU_APR_REGRAB",
            "HRDU_APR_REGPNW",
            "HRDU_APR_PVSPR",
            "HRDU_APR_REGPRO",
            "HRDU_APR_PENPO",
            "HRDU_APR_REGFPNJ",
            "HRDU_APR_PENFPNJ",
            "HRDU_APR_REGDO",
            "HRDU_APR_REGPO",
            "HRDU_APR_REGRAE",
            "HRDU_APR_REGRAP",
            "HRDU_APR_REGSJ",
            "HRDU_APR_REGSO",
            "HRDU_APR_REGSPK",
            "HRDU_APR_RK",
            "HRDU_APR_REGPPA",
            "HRDU_APR_MAG",
            "HRDU_APR_MBJ",
            "HRDU_APR_REGPLG",
          ],
        },
      ],
    },
  ],

  ROUTES: [
    {
      exact: true,
      route: "/profil",
      hak: [
        "HRDU",
        "ASM",
        "MKT",
        "CRM",
        "INV",
        "ANG",
        "HRDA",
        "HRDU_APR_KEG",
        "HRDU_APR_PR",
        "HRDU_APR_REGRAB",
        "HRDU_APR_REGPNW",
        "HRDU_APR_PVSPR",
        "HRDU_APR_REGPRO",
        "HRDU_APR_PENPO",
        "HRDU_APR_REGFPNJ",
        "HRDU_APR_PENFPNJ",
        "HRDU_APR_REGDO",
        "HRDU_APR_REGPO",
        "HRDU_APR_REGRAE",
        "HRDU_APR_REGRAP",
        "HRDU_APR_REGSJ",
        "HRDU_APR_REGSO",
        "HRDU_APR_REGSPK",
        "HRDU_APR_RK",
        "HRDU_APR_REGPPA",
        "HRDU_APR_MAG",
        "HRDU_APR_MBJ",
        "HRDU_APR_REGPLG",
        "HRDU_APR_CMP",
      ],
      page: Profil,
    },
    {
      exact: true,
      route: "/human-resource/approval/kegiatan",
      hak: ["HRDU", "HRDU_APR_KEG", "HRDU_APR_RK"],
      page: MainApprovalKegiatan,
    },
    {
      exact: true,
      route: "/human-resource/approval/kegiatan/detail/:id",
      hak: ["HRDU", "HRDU_APR_KEG", "HRDU_APR_RK"],
      page: DetailKegiatan,
    },
    {
      exact: true,
      route: "/human-resource/approval/ppa",
      hak: ["HRDU", "HRDU_APR_REGPPA"],
      page: MainApprovalPPA,
    },
    {
      exact: true,
      route: "/human-resource/approval/ppa/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGPPA"],
      page: DetailPPA,
    },
    {
      exact: true,
      route: "/human-resource/approval/rae",
      hak: ["HRDU", "HRDU_APR_REGRAE"],
      page: MainApprovalRAE,
    },
    {
      exact: true,
      route: "/human-resource/approval/rae/detail",
      hak: ["HRDU", "HRDU_APR_REGRAE"],
      page: DetailRAE,
    },
    {
      exact: true,
      route: "/human-resource/approval/rab",
      hak: ["HRDU", "HRDU_APR_REGRAB"],
      page: MainApprovalRAB,
    },
    {
      exact: true,
      route: "/human-resource/approval/rab/detail/:id_rab",
      hak: ["HRDU", "HRDU_APR_REGRAB"],
      page: DetailRAB,
    },
    {
      exact: true,
      route: "/human-resource/approval/rap",
      hak: ["HRDU", "HRDU_APR_REGRAP"],
      page: MainApprovalRAP,
    },
    {
      exact: true,
      route: "/human-resource/approval/rap/detail",
      hak: ["HRDU", "HRDU_APR_REGRAP"],
      page: DetailRAP,
    },
    {
      exact: true,
      route: "/human-resource/approval/penawaran",
      hak: ["HRDU", "HRDU_APR_REGPNW"],
      page: MainApprovalPenawaran,
    },
    {
      exact: true,
      route: "/human-resource/approval/penawaran/detail/:id_penawaran",
      hak: ["HRDU", "HRDU_APR_REGPNW"],
      page: DetailPenawaran,
    },
    {
      exact: true,
      route: "/human-resource/approval/purchase-request",
      hak: ["HRDU", "HRDU_APR_PR"],
      page: MainApprovalPurchaseRequest,
    },
    {
      exact: true,
      route: "/human-resource/approval/purchase-request/detail/:id",
      hak: ["HRDU", "HRDU_APR_PR"],
      page: DetailPurchaseRequest,
    },
    {
      exact: true,
      route: "/human-resource/approval/penerimaan-barang",
      hak: ["HRDU", "HRDU_APR_PENPO"],
      page: MainApprovalPenerimaanBarang,
    },
    {
      exact: true,
      route: "/human-resource/approval/penerimaan-barang/detail/:id",
      hak: ["HRDU", "HRDU_APR_PENPO"],
      page: DetailPenerimaanBarang,
    },
    {
      exact: true,
      route: "/human-resource/approval/seleksi-vendor",
      hak: ["HRDU", "HRDU_APR_PVSPR"],
      page: MainApprovalSeleksiVendor,
    },
    {
      exact: true,
      route: "/human-resource/approval/seleksi-vendor/detail",
      hak: ["HRDU", "HRDU_APR_PVSPR"],
      page: DetailApprovalSeleksiVendor,
    },
    {
      exact: true,
      route: "/human-resource/approval/purchase-order",
      hak: ["HRDU", "HRDU_APR_REGPO"],
      page: MainApprovalPurchaseOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/purchase-order/detail",
      hak: ["HRDU", "HRDU_APR_REGPO"],
      page: DetailApprovalPurchaseOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/sales-order",
      hak: ["HRDU", "HRDU_APR_REGSO"],
      page: MainApprovalSalesOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/sales-order/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGSO"],
      page: DetailSalesOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/surat-perjanjian-kerja",
      hak: ["HRDU", "HRDU_APR_REGSPK"],
      page: MainApprovalSPK,
    },
    {
      exact: true,
      route: "/human-resource/approval/surat-perjanjian-kerja/detail",
      hak: ["HRDU", "HRDU_APR_REGSPK"],
      page: DetailApprovalSPK,
    },
    {
      exact: true,
      route: "/human-resource/approval/faktur-penjualan",
      hak: ["HRDU", "HRDU_APR_REGFPNJ"],
      page: MainApprovalFakturPenjualan,
    },
    {
      exact: true,
      route: "/human-resource/approval/faktur-penjualan/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGFPNJ"],
      page: DetailFakturPenjualan,
    },
    {
      exact: true,
      route: "/human-resource/approval/delivery-order",
      hak: ["HRDU", "HRDU_APR_REGDO"],
      page: MainApprovalDeliveryOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/delivery-order/detail",
      hak: ["HRDU", "HRDU_APR_REGDO"],
      page: DetailApprovalDeliveryOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/surat-jalan",
      hak: ["HRDU", "HRDU_APR_REGSJ"],
      page: MainApprovalSuratJalan,
    },
    {
      exact: true,
      route: "/human-resource/approval/surat-jalan/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGSJ"],
      page: DetailSuratJalan,
    },
    // {
    //   exact: true,
    //   route: "/human-resource/approval/penerimaan-faktur",
    //   hak: ["HRDU", "HRDU_APR_PENFPNJ"],
    //   page: MainApprovalPenerimaanFaktur,
    // },
    // {
    //   exact: true,
    //   route: "/human-resource/approval/penerimaan-faktur/detail",
    //   hak: ["HRDU", "HRDU_APR_PENFPNJ"],
    //   page: DetailApprovalPenerimaanFaktur,
    // },
    {
      exact: true,
      route: "/human-resource/approval/mutasi-antar-gudang",
      hak: ["HRDU", "HRDU_APR_MAG"],
      page: MainApprovalMutasiAntarGudang,
    },
    {
      exact: true,
      route: "/human-resource/approval/mutasi-antar-gudang/detail/:id",
      hak: ["HRDU", "HRDU_APR_MAG"],
      page: DetailMutasiAntarGudang,
    },
    {
      exact: true,
      route: "/human-resource/approval/mutasi-barang-jadi",
      hak: ["HRDU", "HRDU_APR_MBJ"],
      page: MainApprovalMutasiBarangJadi,
    },
    {
      exact: true,
      route: "/human-resource/approval/mutasi-barang-jadi/detail/:id",
      hak: ["HRDU", "HRDU_APR_MBJ"],
      page: DetailMutasiBarangJadi,
    },
    {
      exact: true,
      route: "/human-resource/approval/peluang",
      hak: ["HRDU", "HRDU_APR_REGPLG"],
      page: MainApprovalPeluang,
    },
    {
      exact: true,
      route: "/human-resource/approval/peluang/detail",
      hak: ["HRDU", "HRDU_APR_REGPLG"],
      page: DetailPeluang,
    },
    {
      exact: true,
      route: "/human-resource/approval/compliment",
      hak: ["HRDU", "HRDU_APR_REGCMP"],
      page: MainApprovalCompliment,
    },
    {
      exact: true,
      route: "/human-resource/approval/compliment/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGCMP"],
      page: DetailCompliment,
    },
  ],
};
