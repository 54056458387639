import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  DatePicker,
  TextArea,
  Tr,
  THead,
  TBody,
  Th,
  ThFixed
} from "components";
import { DateConvert, RupiahConvert } from "utilities";
import { ApprovalPurchaseOrderApi } from "api";

const DetailApprovalPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const location = useLocation()
  const no_po = state?.no_po;
  const tab = state?.tab ?? "";
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataPO, setDataPO] = useState([]);
  console.log("con", dataPO)
  const [approveStatus, setApproveStatus] = useState("V");
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    ApprovalPurchaseOrderApi.getSingle({ no_purchase_order: state.no_po })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : "V";
        setDataPO(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoPurchaseOrder = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const checkStatus = () => {
      if (modalConfig.type === "revise") {
        return "REV";
      }

      if (modalConfig.type === "approve") {
        return "APP";
      }

      if (modalConfig.type === "reject") {
        return "REJ";
      }
    };

    const finalValues = {
      no_transaksi: dataPO.detail.no_transaksi,
      status_approval: checkStatus(),
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataPO.detail.baseline,
    };

    ApprovalPurchaseOrderApi.save(finalValues)
      .then(() =>
        history.push("/human-resource/approval/purchase-order", {
          alert: {
            show: true,
            variant: "primary",
            text: "Approval berhasil disimpan!",
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Approval gagal disimpan!",
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle("Purchase Order");
    no_po ? getInitialData() : whenNoPurchaseOrder();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td>
          <div className="pl-4 pr-2">: </div>
        </td>
        <td>{value}</td>
      </tr>
    )

    return (
      <Row>
        <Col>
          <table>
            <tbody>
              <InfoItem
                title="Tgl. Purchase Order"
                value={dataPO?.detail?.tgl_purchase_order ? DateConvert(new Date(dataPO?.detail?.tgl_purchase_order)).detail : "-"}
              />
              <InfoItem
                title="No. Purchase Order"
                value={dataPO?.detail?.no_purchase_order ? dataPO?.detail?.no_purchase_order : "-"}
              />
              <InfoItem
                title="Kode Vendor"
                value={dataPO?.detail?.kode_vendor ? dataPO?.detail?.kode_vendor : "-"}
              />
              <InfoItem
                title="Contact Person"
                value={dataPO?.detail?.contact_person ? dataPO?.detail?.contact_person : "-"}
              />
              <InfoItem
                title="Alamat Vendor"
                value={dataPO?.detail?.alamat_vendor ? dataPO?.detail?.alamat_vendor : "-"}
              />
              <InfoItem
                title="Tgl. Pengiriman"
                value={dataPO?.detail?.tgl_pengiriman ? DateConvert(new Date(dataPO?.detail?.tgl_pengiriman)).detail : "-"}
              />
              <InfoItem
                title="Jangka Waktu Pembayaran"
                value={dataPO?.detail?.pembayaran ? dataPO?.detail?.pembayaran : "-"}
              />
              <InfoItem
                title="Alamat Tujuan Pengiriman"
                value={dataPO?.detail?.alamat_tujuan_pengiriman ? dataPO?.detail?.alamat_tujuan_pengiriman : "-"}
              />
              <InfoItem
                title="Keterangan"
                value={dataPO?.detail?.keterangan ? dataPO?.detail?.keterangan : "-"}
              />
              <InfoItem
                title="Status Approval"
                value={dataPO?.detail?.status_approval ? dataPO?.detail?.status_approval : "-"}
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const TableSection = () => {
    const dataDiskon = dataPO?.detail?.diskon ? dataPO.detail.diskon : 0;
    const dataPPN = dataPO?.detail?.ppn ? dataPO.detail.ppn : 0;
    const dataTable = dataPO?.detail?.po_detail ? dataPO.detail.po_detail : [];

    const getTotal = () => {
      const subTotal = dataTable.reduce((prev, current) => {
        const qty = current.qty_order ? current.qty_order : 0;
        const price = current.harga_kesepakatan ? current.harga_kesepakatan : 0;
        const total = parseInt(price) * parseFloat(qty);
        return parseInt(parseInt(prev) + parseInt(total));
      }, 0);

      return subTotal;
    };
    const getDiscount = () => {
      const total = getTotal();
      const discount = parseFloat(dataDiskon);
      const totalDiscount = (total / 100) * discount;

      return parseInt(totalDiscount);
    };
    const getTotalAfterDiscount = () => {
      const total = getTotal();
      const totalDiscount = getDiscount();
      const totalAfterDiscount = total - totalDiscount;

      return parseInt(totalAfterDiscount);
    };
    const getPPN = () => {
      const totalAfterDiscount = getTotalAfterDiscount();
      const ppn = parseFloat(dataPPN);
      const totalDiscount = (totalAfterDiscount / 100) * ppn;

      return parseInt(totalDiscount);
    };
    const getTotalAfterPPN = () => {
      const totalAfterDiscount = getTotalAfterDiscount();
      const totalPPN = getPPN();
      const totalAfterPPN = totalAfterDiscount + totalPPN;

      return parseInt(totalAfterPPN);
    };
    const getSubTotal = (qty, harga) => {
      const dataQty = qty ? qty : 0;
      const dataHarga = harga ? harga : 0;
      const total = parseFloat(dataQty) * parseInt(dataHarga);

      return RupiahConvert(total.toString()).detail;
    };
    const Td = ({children, colSpan, rowSpan, textRight, className, ...props}) => (
      <td 
        {...props}
        colSpan={colSpan}
        rowSpan={rowSpan}
        className={`p-1 px-2 align-middle ${textRight && 'text-right'} ${className}`}
        style={{
          ...props.style,
          fontSize: '14px'
        }}
      >
        {children}
      </td>
    )
    const TdFixed = ({children, colSpan, rowSpan, className, textRight, ...props}) => (
      <td 
        {...props}
        colSpan={colSpan}
        rowSpan={rowSpan}
        className={`p-1 px-2 text-center align-middle ${textRight && 'text-right'} ${className}`}
        style={{
          ...props.style,
          fontSize: '14px'
        }}
      >
        {children}
      </td>
    )

    return (
      <div style={{marginBottom:"-16px"}}>
        <Table bordered hovered size="sm">
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Kode Barang</ThFixed>
              <Th>Item Purchase Order</Th>
              <Th width={100}>Qty. Seleksi Vendor</Th>
              <Th width={140}>Satuan Beli</Th>
              <Th width={150}>Harga Kesepakatan</Th>
              <Th width={150}>Sub Total</Th>  
            </Tr>
          </THead>
          <TBody>
            {dataTable.length > 0 
              ? dataTable.map((val, index) => (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kode_item ?? "-"}</TdFixed>
                  <Td>{val.nama_item ?? "-"}</Td>
                  <Td textRight>{val.qty_order ? parseFloat(val.qty_order ?? 0).toPrecision() : ''}</Td>
                  <Td>{val.nama_satuan ?? "-"}</Td>
                  <Td textRight>{val.harga_kesepakatan ? RupiahConvert(parseInt(val.harga_kesepakatan).toString()).detail : "-"}</Td>
                  <Td textRight>{getSubTotal(val.qty_order, val.harga_kesepakatan)}</Td>
                </Tr>))
              : <DataStatus text="Tidak ada data" />
            }
            {/* Total */}
            <Tr>
              <Td colSpan={6} className="text-right font-weight-bold">Total</Td>
              <Td className="text-right font-weight-bold">{RupiahConvert(getTotal().toString()).detail}</Td>
            </Tr>
            {/* Diskon */}
            <Tr>
              <Td colSpan={6} className="text-right  font-weight-bold">
                <div className="d-flex justify-content-end align-items-baseline">
                  <div className="mr-3">Diskon (%)</div>
                  <div style={{ width: "50px" }}>
                    <input
                      type="number"
                      style={{paddingRight:"0px"}}
                      name="diskon"
                      className={`form-control form-control-sm text-right`} //${errors.diskon && touched.diskon && "border-danger"}
                      value={dataDiskon}
                      // onChange={handleChange}
                      min={0}
                      max={100}
                      required
                      readOnly={true}
                    />
                  </div>
                </div>
              </Td>
              {/* <Td className="text-right font-weight-bold">{parseFloat(diskon || 0)}</Td> */}
              <Td className="text-right font-weight-bold">{RupiahConvert(getDiscount().toString()).detail}</Td>
            </Tr>
            {/* Total Setelah Diskon */}
            <Tr className="bg-light">
              <Td colSpan={6} className="text-right font-weight-bold">Total Seteleah Dikurangi Diskon</Td>
              <Td className="text-right font-weight-bold">{RupiahConvert(getTotalAfterDiscount().toString()).detail}</Td>
            </Tr>
            {/* PPN */}
            <Tr>
              <Td colSpan={6} className="text-right  font-weight-bold">
                <div className="d-flex justify-content-end align-items-baseline">
                  <div className="mr-3">PPN (%)</div>
                  <div style={{ width: "50px" }}>
                    <input
                      type="number"
                      style={{paddingRight:"0px"}}
                      name="diskon"
                      className={`form-control form-control-sm text-right`} //${errors.diskon && touched.diskon && "border-danger"}
                      value={dataPPN}
                      // onChange={handleChange}
                      min={0}
                      max={100}
                      required
                      readOnly={true}
                    />
                  </div>
                </div>
              </Td>
              <Td className="text-right font-weight-bold">{RupiahConvert(getTotalAfterPPN().toString()).detail}</Td>
            </Tr>
            {/* Total Setelah PPN */}
            <Tr className="bg-light">
              <Td colSpan={6} className="text-right font-weight-bold">Total Setelah Ditambah PPN</Td>
              <Td className="text-right font-weight-bold">{RupiahConvert(getTotalAfterPPN().toString()).detail}</Td>
            </Tr>
          </TBody>
        </Table>
      </div>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <h6>
            <b>
              {modalConfig.title === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.title}{" "}
              dengan catatan :
            </b>
          </h6>
          <span>{values.catatan}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type
            }
            variant={
              modalConfig.type === "approve"
                ? "success"
                : modalConfig.type === "revise"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ values, handleChange, validateForm, errors, touched, setTouched, dirty }) => {
    const dataPengaju = dataPO?.approval ? dataPO.approval : [];
    const dataDetail = dataPO.detail ? dataPO.detail : "-";

    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <div>
        {dataPengaju.length > 1 && <hr />}
        {tab !== "history" && (
          <div>
            <div className="mt-auto"><b style={{fontSize:"15px"}}>Catatan Approval Purchase Order</b></div>
            <TextArea
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
              rows={5}
            />
            <div className="d-flex justify-content-end mt-5">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "reject",
                          title: <span className="text-danger">Reject Purchase Order</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "approve",
                          title: (
                            <span className="text-success">
                              {approveStatus === "A" ? "Approve" : "Verify"} Purchase Order
                            </span>
                          ),
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "reject",
                          title: <span className="text-danger">Reject Purchase Order</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "revise",
                          title: <span className="text-warning">Revise Purchase Order</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "approve",
                          title: (
                            <span className="text-success">
                              {approveStatus === "A" ? "Approve" : "Verify"} Purchase Order
                            </span>
                          ),
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const CatatanApprovalPO = () => {
    const dataPengaju = dataPO?.approval ? dataPO.approval : [];

    const InfoItem = ({ labelHeader, karyawanName, labelCatatan, catatanValue }) => (
      <>
          <small>{labelHeader}</small>
          <p><b>{karyawanName}</b></p>
          <small>{labelCatatan}</small>
          <p><b>{catatanValue}</b></p>
      </>
    );
    return (
      <div className="d-flex">
        {dataPO?.detail?.stakeholder === undefined
          ? <div className="text-center"><small>Memuat Data...</small></div>
          : dataPO?.detail?.stakeholder.map((val, index) => (
              <>
                  <div className="mr-5" sm key={index}>
                      <InfoItem
                          labelHeader={val.status_approval === "APP" 
                              ? "Pengesah" 
                              : index !== 0 
                              ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}` : ""
                          }
                          karyawanName={index !== 0 ? val.nama_karyawan ?? "-" : ""}
                          labelCatatan={val.status_approval === "APP" 
                              ? "Catatan Pengesah" 
                              : index !== 0 
                              ? `Catatan Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}` : ""
                          }
                          catatanValue={index !== 0 ? val.catatan : ""}
                      />
                  </div>
              </>
          ))}
      </div>
    )
  };

  return (
    <div>
      {isPageLoading 
        ? <DataStatus loading={true} text="Memuat data . . ." />
        : isFetchingFailed 
        ? <DataStatus text="Data gagal dimuat!" />
        : <div>
            <div className="d-flex align-items-flex-end">
              <div className="mt-auto"><b style={{fontSize:"15px"}}>Detail Data Purchase Order</b></div>
              <div className="d-flex align-items-center ml-auto mb-4">
                <BackButton onClick={() => history.push("/human-resource/approval/purchase-order", { ...location?.state })} />
              </div>
            </div>

            {/* Info Section */}
            <Card className="mb-5">
              <div className="p-2">
                <InfoSection />
              </div>
            </Card>

            {/* Table Purchase Order */}
            <div className="mt-auto"><b style={{fontSize:"15px"}}>List Item Purchase Order</b></div>
            <Card className="mb-5">
              <TableSection />
            </Card>

            {/* Catatan Approval */}
            <div className="mt-auto"><b style={{fontSize:"15px"}}>Approval Purchase Order</b></div>
            <Card className="mb-5">
              <Card.Body>
                <CatatanApprovalPO />
              </Card.Body>
            </Card>

            {/* Catatan Approval Purchase Order */}
            {!isFetchingFailed && !isPageLoading && (
              <Formik
                initialValues={{ catatan: "" }}
                validationSchema={Yup.object().shape({
                  catatan: Yup.string().required("Masukan Catatan"),
                })}
                onSubmit={formSubmitHandler}>

                {({ values, isSubmitting, handleChange, handleSubmit, errors, touched, validateForm, dirty }) => (
                  <>
                    <FormCard
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      validateForm={validateForm}
                      dirty={dirty}
                    />
                    <ModalSection
                      values={values}
                      isSubmitting={isSubmitting}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                    />
                  </>
                )}
              </Formik>
            )}
          </div>
      }
    </div>
  );
};

export default DetailApprovalPurchaseOrder;



{/* <tbody>
            {dataTable.length > 0 ? (
              dataTable.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{val.kode_item}</Td> */}
                  {/* <Td>
                    {val.dimensi === "3"
                      ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                      : val.dimensi === "2"
                        ? `${val.nama_item} (${val.pj} X ${val.lb})`
                        : val.dimensi === "1"
                          ? `${val.nama_item} (${val.pj})`
                          : val.dimensi === "0"
                            ? val.nama_item
                            : ""}
                  </Td> */}
                  {/* <Td>{val.nama_item}</Td>
                  <Td>{val.nama_satuan}</Td>
                  <Td className="text-right">{val.qty_order}</Td>
                  <Td className="text-right">
                    {val.harga_kesepakatan
                      ? RupiahConvert(parseInt(val.harga_kesepakatan).toString()).detail
                      : "-"}
                  </Td>
                  <Td className="text-right">
                    {getSubTotal(val.qty_order, val.harga_kesepakatan)}
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <Th colSpan={6} className="bg-light text-right">
                Total
              </Th>
              <Th className="bg-light text-right">{RupiahConvert(getTotal().toString()).detail}</Th>
            </tr>
            <tr>
              <Th colSpan={6} className="text-right">
                Diskon ({dataDiskon}%)
              </Th>
              <Th className="text-right">{RupiahConvert(getDiscount().toString()).detail}</Th>
            </tr>
            <tr>
              <Th colSpan={6} className="bg-light text-right">
                Total Setelah Diskon
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(getTotalAfterDiscount().toString()).detail}
              </Th>
            </tr>
            <tr>
              <Th colSpan={6} className="text-right">
                PPN ({dataPPN}%)
              </Th>
              <Th className="text-right">{RupiahConvert(getPPN().toString()).detail}</Th>
            </tr>
            <tr>
              <Th colSpan={6} className="bg-light text-right">
                Total Setelah PPN
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(getTotalAfterPPN().toString()).detail}
              </Th>
            </tr>
          </tfoot> */}

{/* <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Purchase Order</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <TableSection />
            </>
          )}
        </Card.Body>
      </Card> */}
