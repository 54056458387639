import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from "formik"
import { ApprovalSeleksiVendorApi } from 'api'
import Axios from "axios"

const ModalFilterSV = ({ type, modalFilter, setModalFilter, data, setData }) => {
  const [dataJabatan, setDataJabatan] = useState([{ label: "Semua", value: undefined }])
  const [dataPembuatPengajuan, setDataPembuatPengajuan] = useState([{ label: "Semua", value: undefined }])
  const [dataVendor, setDataVendor] = useState([{ label: "Semua", value: undefined }])
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([{ label: "Semua", value: undefined }])
  const [dataSatuanBeli, setDataSatuanBeli] = useState([{ label: "Semua", value: undefined }])
  const [dropdownStatusApproval, setDropdownStatusApproval] = useState([
    { label: 'Semua', value: undefined },
    { label: 'APPROVED', value: 'APP' },
    { label: 'VERIFIED', value: 'VER' },
    { label: 'REVISION', value: 'REV' },
    { label: 'REJECTED', value: 'REJ' },
    { label: 'PENDING', value: 'PEN' }
  ])
  const [loading, setLoading] = useState({ all: false, karyawan: true })

  const getDropdown = () => {
    setLoading({ ...loading, all: true })

    Axios.all([
      ApprovalSeleksiVendorApi.dropdownJabatan(),
      ApprovalSeleksiVendorApi.dropdownPembuatPengajuan(),
      ApprovalSeleksiVendorApi.dropdownVendor(),
      ApprovalSeleksiVendorApi.dropdownSeleksiVendor(),
      ApprovalSeleksiVendorApi.dropdownSatuan(),
    ])
      .then(Axios.spread(( jab, pengajuan, ven, seleksi, satuan ) => {
        const mapJabatan = jab?.data?.data ? jab?.data?.data.map(val => ({ label: val.nama_jabatan, value: val.id_jabatan })) : []
        const mapPengajuan = pengajuan?.data?.data ? pengajuan?.data?.data.map(val => ({ label: val.nama_karyawan, value: val.id_karyawan })) : []
        const mapVendor = ven?.data?.data ? ven?.data?.data.map(val => ({ label: val.nama_vendor, value: val.id_vendor })) : []
        const mapSeleksiVendor = seleksi?.data?.data ? seleksi?.data?.data.map(val => ({ label: val.nama_item, value: val.id_item_buaso })) : []
        const mapSatuan = satuan?.data?.data ? satuan?.data?.data.map(val => ({ label: val.nama_satuan, value: val.nama_satuan })) : []

        setDataJabatan([ ...dataJabatan, ...mapJabatan ])
        setDataPembuatPengajuan([{label: 'Semua', value: undefined}, ...mapPengajuan ])
        setDataVendor([ ...dataVendor, ...mapVendor ])
        setDataSeleksiVendor([ ...dataSeleksiVendor, ...mapSeleksiVendor ])
        setDataSatuanBeli([ ...dataSatuanBeli, ...mapSatuan ])
      }))
      .finally(() => setLoading({ ...loading, all: false, karyawan: false }))
  }
  const getDropdownPengaju = (value) => {
    ApprovalSeleksiVendorApi.dropdownPembuatPengajuan({ id_jabatan: value })
      .then(pengajuan => {
        const mapDataPengajuan = pengajuan?.data?.data ? pengajuan.data.data.map(val => ({label: val?.nama_karyawan, value: val?.id_karyawan})) : []
        setDataPembuatPengajuan([{label: 'Semua', value: undefined}, ...mapDataPengajuan])
      })
      .finally(() => setLoading({...loading, karyawan: false }))
  }
  useEffect(() => {
    getDropdown()
  }, [])
  useEffect(() => {
    data.jabatan && getDropdownPengaju(data.jabatan)
  }, [data.jabatan])

  const formInitialValues = {
    jabatan: data?.filter?.jabatan,
    pengaju: data?.filter?.pengaju,
    tgl_seleksi_vendor_mulai: data?.filter?.tgl_seleksi_vendor_mulai,
    tgl_seleksi_vendor_selesai: data?.filter?.tgl_seleksi_vendor_selesai,
    tgl_purchase_request_mulai: data?.filter?.tgl_purchase_request_mulai,
    tgl_purchase_request_selesai: data?.filter?.tgl_purchase_request_selesai,
    vendor: data?.filter?.vendor,
    id_item: data?.filter?.id_item,
    satuan: data?.filter?.satuan,
    status: data?.filter?.status
  }
  const formSubmitHandler = (values) => {
      const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

      if (checkActive) {
        setData({ 
            filter: {
                ...values,
                active: true
            },
            pagination: {
                ...data.pagination,
                page: 1
            }
        })
      } else { 
          setData({ 
              ...data,
              filter: {
                  ...values,
                  active: false
              }
          })
      }

      setModalFilter(false)
  }
  const onChangeSV = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_seleksi_vendor_mulai: startDate,
        tgl_seleksi_vendor_selesai: endDate,
    })
  }
  const onChangePR = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_purchase_request_mulai: startDate,
        tgl_purchase_request_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
      jabatan: undefined,
      pengaju: undefined,
      tgl_seleksi_vendor_mulai: undefined,
      tgl_seleksi_vendor_selesai: undefined,
      tgl_purchase_request_mulai: undefined,
      tgl_purchase_request_selesai: undefined,
      vendor: undefined,
      id_item: undefined,
      satuan: undefined,
      status: undefined
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (

      <Modal
        show={modalFilter}
        onHide={() => setModalFilter(false)}>
          
        <Modal.Header closeButton><b>Filter Data</b></Modal.Header>
        <Modal.Body>
          <SelectSearch
            key={values.jabatan}
            label="Jabatan"
            placeholder="Pilih jabatan"
            defaultValue={dataJabatan?.find(item => item.value === values.jabatan)}
            option={dataJabatan}
            onChange={val => {
              // setFieldValue('jabatan', val.value)
              setValues({ ...values, jabatan: val.value, pengaju: undefined })
              getDropdownPengaju(val.value, values, setValues)
            }}
            loading={loading.all}
          />
          <SelectSearch
            key={values.pengaju}
            label="Nama Pembuat Pengajuan"
            placeholder="Pilih nama pembuat pengajuan"
            defaultValue={dataPembuatPengajuan?.find(item => item.value === values.pengaju)}
            option={dataPembuatPengajuan}
            onChange={val => setFieldValue('pengaju', val.value)}
            isDisabled={Boolean(values.jabatan) ? false : true}
            loading={loading.karyawan}
          />
          <DatePicker
            selectsRange
            label="Tgl. Seleksi Vendor"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal seleksi vendor"
            startDate={values.tgl_seleksi_vendor_mulai ? new Date(values.tgl_seleksi_vendor_mulai) : ''}
            endDate={values.tgl_seleksi_vendor_selesai ? new Date(values.tgl_seleksi_vendor_selesai) : ''}
            onChange={(dates) => onChangeSV(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Purchase Request"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal purchase request"
            startDate={values.tgl_purchase_request_mulai ? new Date(values.tgl_purchase_request_mulai) : ''}
            endDate={values.tgl_purchase_request_selesai ? new Date(values.tgl_purchase_request_selesai) : ''}
            onChange={(dates) => onChangePR(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.vendor}
            label="Vendor"
            placeholder="Pilih vendor"
            defaultValue={dataVendor?.find(item => item.value === values.vendor)}
            option={dataVendor}
            onChange={val => setFieldValue('vendor', val.value)}
            loading={loading.all}
          />
          <SelectSearch
            key={values.id_item}
            label="Item Seleksi Vendor"
            placeholder="Pilih item seleksi vendor"
            defaultValue={dataSeleksiVendor?.find(item => item.value === values.id_item)}
            option={dataSeleksiVendor}
            onChange={val => setFieldValue('id_item', val.value)}
            loading={loading.all}
          />
          <SelectSearch
            key={values.satuan}
            label="Satuan Beli"
            placeholder="Pilih satuan beli"
            defaultValue={dataSatuanBeli?.find(item => item.value === values.satuan)}
            option={dataSatuanBeli}
            onChange={val => setFieldValue('satuan', val.value)}
            loading={loading.all}
          />
          {type === "history" &&
            <SelectSearch
              key={values.status}
              label="Status Approval"
              placeholder="Pilih status approval"
              defaultValue={dropdownStatusApproval?.find(item => item.value === values.status)}
              option={dropdownStatusApproval}
              onChange={val => setFieldValue('status', val.value)}
              loading={loading.all}
            />
          }
        </Modal.Body>
        
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => onResetButtonClick(values, setValues)}
          >
            RESET
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            FILTER
          </Button>
        </Modal.Footer>
      </Modal >

      )}
  </Formik>
  )
}

export default ModalFilterSV