import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  DatePicker,
  TextArea,
} from "components";
import { DateConvert, RupiahConvert } from "utilities";
import { PeluangApi } from "api";
import { TableBarangJadi } from "./Table";
import { IoCheckboxOutline, IoCloseCircleOutline } from "react-icons/io5";

const DetailPeluang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const no_peluang = state?.no_peluang;
  const tab = state?.tab ?? "";
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataPeluang, setDataPeluang] = useState({});
  const [approveStatus, setApproveStatus] = useState("v");
  const [dataApproval, setDataApproval] = useState([]);
  const [dataSelectJenisBarangJadi, setDataSelectJenisBarangJadi] = useState([]);
  const [dataSelectAnalisaBarangJadi, setDataSelectAnalisaBarangJadi] = useState([]);
  const [dataSelectLabourCost, setDataSelectLabourCost] = useState([]);
  const [dataSelectMachineProcess, setDataSelectMachineProcess] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PeluangApi.getSingle({ no_peluang })
      .then((peluang) => {
        const checkPeluang = peluang.data.data.detail ?? {};
        const checkApp = peluang.data.data.approval ?? [];
        const checkStatus = peluang.data.data.behavior ?? "v";

        setDataPeluang(checkPeluang);
        setDataApproval(checkApp);
        setApproveStatus(checkStatus);
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoPeluang = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  useEffect(() => {
    setNavbarTitle("Peluang");
    no_peluang ? getInitialData() : whenNoPeluang();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );
    const ShowData = ({ title, text, line }) => (
      <div>
        <small className="text-secondary" style={{ opacity: 0.9, textTransform: "capitalize" }}>
          {title}
        </small>
        <div className="text-dark">
          <div style={{ overflow: "auto" }}>{text ? text : "-"}</div>
        </div>

        {line && <hr className="my-2" />}
      </div>
    );

    return (
      <>
        <Row>
          <Col>
            <table style={{ fontSize: "14px" }}>
              <tbody>
                <InfoItem
                  title="Tgl. Peluang"
                  value={
                    dataPeluang.tgl_peluang
                      ? DateConvert(new Date(dataPeluang.tgl_peluang)).detail
                      : "-"
                  }
                />
                <InfoItem
                  title="No. Peluang"
                  value={dataPeluang.no_peluang ? dataPeluang.no_peluang : "-"}
                />
                <InfoItem
                  title="Tanggal Selesai"
                  value={
                    dataPeluang.tgl_selesai
                      ? DateConvert(new Date(dataPeluang.tgl_selesai)).detail
                      : "-"
                  }
                />
              </tbody>
            </table>
          </Col>
          <Col>
            <table style={{ fontSize: "14px" }}>
              <tbody>
                <InfoItem
                  title="Peringkat Peluang"
                  value={
                    dataPeluang.nama_peringkat_peluang ? dataPeluang.nama_peringkat_peluang : "-"
                  }
                />
                <InfoItem
                  title="Customer"
                  value={dataPeluang.nama_customer ? dataPeluang.nama_customer : "-"}
                />
                <InfoItem
                  title="Proyek"
                  value={dataPeluang.nama_proyek ? dataPeluang.nama_proyek : "-"}
                />
                <InfoItem title="ATT" value={dataPeluang.att ? dataPeluang.att : "-"} />
              </tbody>
            </table>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <ShowData
              title="Keterangan Pengiriman"
              text={dataPeluang.keterangan_pengiriman ?? "-"}
              line={true}
            />
            <ShowData
              title="Lokasi Pengiriman"
              text={dataPeluang.lokasi_pengiriman ?? "-"}
              line={true}
            />
          </Col>
          <Col>
            <ShowData title="Informasi Lainya" text={dataPeluang.lain_lain ?? "-"} line={true} />
            <ShowData
              title="Instalasi"
              text={
                dataPeluang.instalasi === true ? <IoCheckboxOutline /> : <IoCloseCircleOutline />
              }
            />
          </Col>
        </Row>
      </>
    );
  };

  const TableSection = () => {
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [dataLabourCost, setDataLabourCost] = useState([]);
    const [dataMachineProcess, setDataMachineProcess] = useState([]);

    useEffect(() => {
      setDataBarangJadi(dataPeluang.detail ? dataPeluang.detail : []);
      setDataLabourCost(dataPeluang.detail_upah ? dataPeluang.detail_upah : []);
      setDataMachineProcess(dataPeluang.detail_alat_mesin ? dataPeluang.detail_alat_mesin : []);
    }, []);

    const GrandTotalSection = () => {
      const getGrandTotal = () => {
        const totalBarangJadi = dataBarangJadi.reduce((prevValue, currentValue) => {
          const checkQty = currentValue.qty_rae ? parseFloat(currentValue.qty_rae) : "0";
          const checkSatuan = currentValue.harga_satuan_rae
            ? parseInt(currentValue.harga_satuan_rae)
            : "0";
          const subTotal = parseInt(checkQty * checkSatuan);
          const total = parseInt(prevValue + subTotal);

          return total;
        }, 0);

        const totalLabourCost = dataLabourCost.reduce((prevValue, currentValue) => {
          const subTotal = parseInt(
            parseFloat(currentValue.qty).toFixed(2) *
            parseInt(currentValue.unit_price) *
            parseFloat(currentValue.konstanta).toFixed(2)
          );
          const total = parseInt(prevValue + subTotal);

          return total;
        }, 0);

        const totalMachineProcess = dataMachineProcess.reduce((prevValue, currentValue) => {
          const subTotal = parseInt(
            parseFloat(currentValue.qty).toFixed(2) *
            parseInt(currentValue.unit_price) *
            parseFloat(currentValue.konstanta).toFixed(2)
          );
          const total = parseInt(prevValue + subTotal);

          return total;
        }, 0);

        const grandTotal =
          parseInt(totalBarangJadi) + parseInt(totalLabourCost) + parseInt(totalMachineProcess);

        return RupiahConvert(grandTotal.toString()).detail;
      };

      return (
        <div className="mt-4 p-2 px-3 text-right border">
          <b>Grand Total : </b>
          <b className="pl-3">{getGrandTotal()}</b>
        </div>
      );
    };

    return (
      <>
        <TableBarangJadi
          dataBarangJadi={dataBarangJadi}
          dataSelectJenisBarangJadi={dataSelectJenisBarangJadi}
          dataSelectAnalisaBarangJadi={dataSelectAnalisaBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          setDataSelectJenisBarangJadi={setDataSelectJenisBarangJadi}
          setDataSelectAnalisaBarangJadi={setDataSelectAnalisaBarangJadi}
        />
        {/* <hr />
        <TableLabourCost
          dataSelectLabourCost={dataSelectLabourCost}
          dataLabourCost={dataLabourCost}
          setDataLabourCost={setDataLabourCost}
        />
        <hr />
        <TableMachineProcess
          dataSelectMachineProcess={dataSelectMachineProcess}
          dataMachineProcess={dataMachineProcess}
          setDataMachineProcess={setDataMachineProcess}
        /> */}
        {/* <GrandTotalSection /> */}
      </>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? "Approve"
                : modalConfig.type === "VER"
                  ? "Verify"
                  : modalConfig.type === "REJ"
                    ? "Reject"
                    : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "VER"
                  ? "success"
                  : modalConfig.type === "REV"
                    ? "warning"
                    : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );
    console.log(approveStatus);
    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ values, handleChange, validateForm, errors, touched, setTouched, dirty }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Peluang</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == "VER" ? `PEMERIKSA ${val.approval_level}` : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          <div>
            <TextArea
              label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject Peluang</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: <span className="text-success">Approve Peluang</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject Peluang</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REV",
                          title: <span className="text-warning">Revise Peluang</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "VER",
                          title: <span className="text-success">Verify Peluang</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const CatatanApproval = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Peluang</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPeluang.stakeholder.map(
              (val, index) =>
                index !== 0 &&
                val.status_approval !== "PEN" && (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === "VER" || val.status_approval === "REV"
                          ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                          : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                      }
                      value1={val.nama_karyawan ?? "-"}
                      title2="Catatan"
                      value2={val.catatan ?? "-"}
                    />
                  </Col>
                )
            )}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Peluang</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <TableSection />
            </>
          )}
        </Card.Body>
        {console.log(dataPeluang)}
      </Card>
      {!isFetchingFailed && !isPageLoading && tab !== "history" && (
        <Formik
          initialValues={{ catatan: "" }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan Catatan"),
          })}
          onSubmit={(values) => {
            const finalValues = {
              ...values,
              no_transaksi: dataPeluang.no_peluang,
              status_approval: modalConfig.type,
              tgl_approval: DateConvert(new Date()).default,
              approval_baseline: dataPeluang.baseline,
            };

            PeluangApi.save(finalValues)
              .then((res) => {
                history.push("/human-resource/approval/peluang", {
                  alert: {
                    show: true,
                    variant: "primary",
                    text: `${modalConfig.type == "APP"
                      ? "Approve"
                      : modalConfig.type == "REV"
                        ? "Revise"
                        : modalConfig.type === "VER"
                          ? "Verify"
                          : "Reject"
                      } data berhasil!`,
                  },
                });

                const logValues = {
                  no_transaksi: dataPeluang.no_rae,
                  nama_transaksi: "Peluang",
                  baseline: dataPeluang.baseline,
                  status_approval: modalConfig.type,
                  no_transaksi_ref: dataPeluang.no_peluang,
                  baseline_ref: dataPeluang.baseline,
                  keterangan_transaksi:
                    modalConfig.type === "APP"
                      ? "Approve Rencana Anggaran Estimasi"
                      : modalConfig.type === "VER"
                        ? "Verifikasi Rencana Anggaran Estimasi"
                        : "Reject Rencana Anggaran Estimasi",
                };
                PeluangApi.saveLogProgess(logValues);
              })
              .catch((err) => {
                setAlertConfig({
                  show: true,
                  variant: "danger",
                  text: `Simpan approval gagal! (${err?.response?.data?.message ?? ""})`,
                });
              })
              .finally(() => setModalConfig({ show: false }));
          }}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            errors,
            touched,
            validateForm,
            dirty,
          }) => (
            <>
              <FormCard
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                validateForm={validateForm}
                dirty={dirty}
              />
              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      )}
      {!isFetchingFailed && !isPageLoading && tab === "history" && <CatatanApproval />}
    </>
  );
};

export default DetailPeluang;
