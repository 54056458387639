import Services from '../../services'

class ComplimentApi {
    getPage(params) {
        return Services.get('/hrdu/approval_compliment/list/', { params })
    }

    getHistory(params) {
        return Services.get('/hrdu/approval_compliment/history/', { params })
    }

    getSingle(params) {
        return Services.get('/hrdu/approval_compliment/detail', { params })
    }

    approve(data) {
        return Services.post('/hrdu/approval_compliment/approve', data)
    }
}

export default new ComplimentApi()