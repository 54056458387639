import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from "formik"
import { ApprovalPurchaseOrderApi } from "api";
import Axios from "axios"
import { First } from 'react-bootstrap/esm/PageItem'

const ModalFilterHistory = ({ type, modalFilter, setModalFilter, data, setData }) => {
  const [dataJabatan, setJabatan] = useState([{label: 'Semua', value: undefined}])
  const [pembuatPengajuan, setPembuatPengajuan] = useState([{label: 'Semua', value: undefined}])
  const [dataVendor, setVendor] = useState([{label: 'Semua', value: undefined}])
  const [itemPO, setItemPO] = useState([{label: 'Semua', value: undefined}])
  const [dataStatusApproval, setDataStatusApproval] = useState([
    {
        label: 'Semua',
        value: undefined
    },
    {
        label: 'APPROVED',
        value: 'APP'
    },
    {
        label: 'VERIFIED',
        value: 'VER'
    },
    {
        label: 'REVISION',
        value: 'REV'
    },
    {
        label: 'REJECTED',
        value: 'REJ'
    },
    {
        label: 'PENDING',
        value: 'PEN'
    }
  ])
  const [loading, setLoading] = useState({
    all: false,
    karyawan: true,
  })
  
  const formInitialValues = {
    jabatan: data?.filter?.jabatan,
    pengaju: data?.filter?.pengaju,
    tgl_purchase_order_mulai: data?.filter?.tgl_purchase_order_mulai,
    tgl_purchase_order_selesai: data?.filter?.tgl_purchase_order_selesai,
    vendor: data?.filter?.vendor,
    id_item_po: data?.filter?.id_item_po,
    status_approval: data?.filter?.status_approval
  }
  const formSubmitHandler = (values) => {
      const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

      if (checkActive) {
        setData({ 
            filter: {
                ...values,
                active: true
            },
            pagination: {
                ...data.pagination,
                page: 1
            }
        })
      } else { 
          setData({ 
              ...data,
              filter: {
                  ...values,
                  active: false
              }
          })
      }

      setModalFilter(false)
  }
// REQUSET DATA SERVER
  const getDataDropdown = () => {
    setLoading({ ...loading, all: true })

    Axios.all([
      ApprovalPurchaseOrderApi.dropdownJabatan(),
      ApprovalPurchaseOrderApi.dropdownVendor(),
      ApprovalPurchaseOrderApi.dropdownItemBarang(),
      ApprovalPurchaseOrderApi.dropdownKaryawan()
    ])
    .then(Axios.spread((jab, ven, item, karyawan) => {
        const mapDataJabatan = jab?.data?.data ? jab.data.data.map(val => ({label: val?.nama_jabatan, value: val?.id_jabatan})) : []
        const mapDataVendor = ven?.data?.data ? ven.data.data.map(val => ({label: val?.nama_vendor, value: val?.id_vendor})) : []
        const mapDataItem = item?.data?.data ? item.data.data.map(val => ({label: val?.nama_item, value: val?.id_item_buaso})) : []
        const mapDataKaryawan = karyawan?.data?.data ? karyawan.data.data.map(val => ({label: val?.nama_karyawan, value: val?.id_karyawan})) : []

        setJabatan([...dataJabatan, ...mapDataJabatan])
        setVendor([...dataVendor, ...mapDataVendor])
        setItemPO([...itemPO, ...mapDataItem])
        setPembuatPengajuan([{label: 'Semua', value: undefined}, ...mapDataKaryawan])
    }))
    .finally(() => setLoading({...loading, all: false, karyawan: false }))
  }
  const getDropdownPengaju = (value) => {
    ApprovalPurchaseOrderApi.dropdownKaryawan({ id_jabatan: value })
      .then(karyawan => {
        const mapDataKaryawan = karyawan?.data?.data ? karyawan.data.data.map(val => ({label: val?.nama_karyawan, value: val?.id_karyawan})) : []
        setPembuatPengajuan([{label: 'Semua', value: undefined}, ...mapDataKaryawan])
      })
      .finally(() => setLoading({...loading, karyawan: false }))
  }
  const onChangePO = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_purchase_order_mulai: startDate,
        tgl_purchase_order_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
        jabatan: undefined,
        pengaju: undefined,
        tgl_purchase_order_mulai: undefined,
        tgl_purchase_order_selesai: undefined,
        vendor: undefined,
        id_item_po: undefined,
        status_approval: undefined
    })
  }

  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown()
  }, [])
  useEffect(() => {
    data.jabatan && getDropdownPengaju(data.jabatan)
  }, [data.jabatan])

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (

      <Modal
        show={modalFilter}
        onHide={() => setModalFilter(false)}>
          
        <Modal.Header closeButton><b>Filter Data</b></Modal.Header>
        <Modal.Body>
          <SelectSearch
            key={values.jabatan}
            label="Jabatan"
            placeholder="Pilih jabatan"
            defaultValue={dataJabatan?.find(item => item.value === values.jabatan)}
            option={dataJabatan}
            onChange={val => {
              // setFieldValue('jabatan', val.value)
              setValues({ ...values, jabatan: val.value, pengaju: undefined })
              getDropdownPengaju(val.value, values, setValues)
            }}
            loading={loading.all}
          />
          <SelectSearch
            key={values.pengaju}
            label="Nama Pembuat Pengajuan"
            placeholder="Pilih nama pembuat pengajuan"
            defaultValue={pembuatPengajuan?.find(item => item.value === values.pengaju)}
            option={pembuatPengajuan}
            onChange={val => setFieldValue('pengaju', val.value)}
            isDisabled={Boolean(values.jabatan) ? false : true}
            loading={loading.karyawan}
          />
          <DatePicker
            selectsRange
            label="Tgl. Purchase Order"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih tanggal purchase order"
            startDate={values.tgl_purchase_order_mulai ? new Date(values.tgl_purchase_order_mulai) : ''}
            endDate={values.tgl_purchase_order_selesai ? new Date(values.tgl_purchase_order_selesai) : ''}
            onChange={(dates) => onChangePO(dates, values, setValues)}
            monthsShown={2}
          />
          <SelectSearch
            key={values.vendor}
            label="Vendor"
            placeholder="Pilih vendor"
            defaultValue={dataVendor?.find(item => item.value === values.vendor)}
            option={dataVendor}
            onChange={val => setFieldValue('vendor', val.value)}
            loading={loading.all}
          />
          <SelectSearch
            key={values.id_item_po}
            label="Item Purchase Order"
            placeholder="Pilih item purchase order"
            defaultValue={itemPO?.find(item => item.value === values.id_item_po)}
            option={itemPO}
            onChange={val => setFieldValue('id_item_po', val.value)}
            loading={loading.all}
          />
          <SelectSearch
            key={values.status_approval}
            label="Status Approval"
            placeholder="Pilih status approval"
            defaultValue={dataStatusApproval?.find(item => item.value === values.status_approval)}
            option={dataStatusApproval}
            onChange={val => setFieldValue('status_approval', val.value)}
            loading={loading.all}
          />
        </Modal.Body>
        
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => onResetButtonClick(values, setValues)}
          >
            RESET
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            FILTER
          </Button>
        </Modal.Footer>
      </Modal >

      )}
  </Formik>
  )
}

export default ModalFilterHistory