import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import { Alert, DataStatus, BackButton, TextArea, ActionButton } from "../../../components";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { RupiahConvert, DateConvert } from "../../../utilities";
import { Formik } from "formik";
import { RABApi } from "../../../api";
import { TableLC, TableMP } from "./TableRAB";
import AnalisaBarangJadiRAB from "./AnalisaBarangJadiRAB";

const DetailRAB = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { id_rab } = useParams();
  const { no_rab, tab, baseline } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataListRAB, setDataListRAB] = useState([]);
  const [dataListLC, setDataListLC] = useState([]);
  const [dataListMP, setDataListMP] = useState([]);

  const [dataRAB, setDataRAB] = useState({});
  const [dataApproval, setDataApproval] = useState([]);

  const [profit, setProfit] = useState(10);
  const [prelim, setPrelim] = useState(10);

  const [totalRAB, setTotalRAB] = useState(0);
  const [totalLC, setTotalLC] = useState(0);
  const [totalMP, setTotalMP] = useState(0);

  const [processedData, setProcessedData] = useState({});
  const [modalAnalisaConfig, setModalAnalisaConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const [dataBehavior, setDataBehavior] = useState("");
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle",
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    console.log("tab", tab);
    setIsPageLoading(true);

    // GET SINGLE DETAIL RAB
    Axios.all([RABApi.getSingle({ no_rab, baseline })])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data;

          setDataBehavior(data.data.data.behavior);
          setDataRAB(detail);
          setDataApproval(approval);

          setDataListRAB(detail.detail_barang_jadi);
          setDataListLC(detail.detail_upah);
          setDataListMP(detail.detail_alat_mesin);

          setProfit(parseInt(detail.profit));
          setPrelim(parseInt(detail.prelim));
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  console.log(dataApproval);

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return "-";
  };

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <Row>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. RAE"
                value={dataRAB?.tgl_rae ? getConvertedDate(dataRAB?.tgl_rae) : "-"}
              />
              <InfoItem title="No. RAE" value={dataRAB?.no_rae ?? "-"} />
              <InfoItem
                title="Customer"
                value={dataRAB.nama_customer ? dataRAB.nama_customer : "-"}
              />
              <InfoItem title="ATT" value={dataRAB.att ? dataRAB.att : "-"} />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tanggal Selesai"
                value={
                  dataRAB.tgl_selesai ? DateConvert(new Date(dataRAB.tgl_selesai)).detail : "-"
                }
              />
              <InfoItem
                title="Peringkat Peluang"
                value={dataRAB.nama_peringkat_peluang ? dataRAB.nama_peringkat_peluang : "-"}
              />

              <InfoItem
                title="Tgl. RAB"
                value={dataRAB?.tgl_rab ? getConvertedDate(dataRAB?.tgl_rab) : "-"}
              />
              <InfoItem title="No. RAB" value={dataRAB?.no_rab ?? "-"} />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const GrandTotalSection = ({ totalRAB, totalLC, totalMP }) => {
    const hitungGrandTotal = () => {
      let grandTotal = totalRAB + totalLC + totalMP;
      return RupiahConvert(String(grandTotal)).detail;
    };

    useEffect(() => {
      hitungGrandTotal();
    }, [totalRAB, totalLC, totalMP]);

    return (
      <div className="p-2 bg-light text-right border">
        <b className="text-nowrap pr-3" style={{ fontSize: "14px" }}>
          Grand Total : {hitungGrandTotal()}
        </b>
      </div>
    );
  };

  const TableRAB = ({ dataListRAB, setTotalRAB, profit, prelim }) => {
    // FUNGSI HITUNG TOTAL KESELURUHAN LC
    const totalRAB = () => {
      let totalRAB = dataListRAB.reduce(function (accumulator, { subtotal }) {
        return accumulator + subtotal;
      }, 0);

      setTotalRAB(totalRAB);
      return RupiahConvert(String(totalRAB)).detail;
    };
    return (
      <>
        <div className="p-2 mt-3">
          <b>List Item RAB</b>
        </div>

        <div>
          <table className="table table-bordered bg-white table-sm">
            <thead className="text-center bg-light">
              <tr key="head1">
                <th rowSpan={2} className="align-middle" style={tableStyling}>
                  No.
                </th>
                <th rowSpan={2} className="align-middle" style={{ ...tableStyling, width: "20px" }}>
                  Kode Item
                </th>
                <th rowSpan={2} className="align-middle" style={tableStyling}>
                  Nama Item
                </th>
                <th colSpan={2} className="align-middle" style={tableStyling}>
                  Volume
                </th>
                <th
                  rowSpan={2}
                  className="align-middle"
                  style={{ ...tableStyling, width: "130px" }}
                >
                  Unit Cost
                </th>
                <th
                  rowSpan={1}
                  className="align-middle"
                  style={{ ...tableStyling, width: "130px" }}
                >
                  Profit (%)
                </th>
                <th
                  rowSpan={1}
                  className="align-middle"
                  style={{ ...tableStyling, width: "130px" }}
                >
                  Prelim (%)
                </th>
                <th
                  rowSpan={2}
                  className="align-middle"
                  style={{ ...tableStyling, width: "130px" }}
                >
                  Rounded
                </th>
                <th rowSpan={2} className="align-middle" style={tableStyling}>
                  Sub Total (Rp)
                </th>
              </tr>
              <tr key="head2">
                <th style={tableStyling}>Qty</th>
                <th style={tableStyling}>Unit</th>
                <th style={{ ...tableStyling, width: "100px" }}>{profit}</th>
                <th style={{ ...tableStyling, width: "100px" }}>{prelim}</th>
              </tr>
            </thead>
            <tbody>
              {dataListRAB &&
                dataListRAB.length > 0 &&
                dataListRAB.map((val, index) => {
                  val.subtotal = val.qty_rae * val.rounded;
                  val.profit =
                    (parseInt(val.harga_satuan_rae) * (100 + parseInt(profit) ?? 0)) / 100;
                  val.prelim = (parseInt(val.profit) * (100 + parseInt(prelim) ?? 0)) / 100;
                  return (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td style={tableStyling} className="px-2">
                        {val.kode_item}
                      </td>
                      <td style={tableStyling} className="px-2">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setProcessedData({
                              ...val,
                              index: index,
                            });
                            setModalAnalisaConfig({
                              show: true,
                              type: "analisa",
                              title: <span className="text-primary">Analisa Barang Jadi RAB</span>,
                            });
                          }}
                        >
                          {val.nama_item}
                        </a>
                      </td>
                      <td style={tableStyling} className="text-right">
                        {val.qty_rae}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.nama_satuan}
                      </td>
                      <td style={tableStyling} className="text-right">
                        {RupiahConvert(String(parseInt(val.harga_satuan_rae))).detail}
                      </td>
                      <td style={tableStyling} className="text-right">
                        {RupiahConvert(String(parseInt(val.profit))).detail}
                      </td>
                      <td style={tableStyling} className="text-right">
                        {RupiahConvert(String(parseInt(val.prelim))).detail}
                      </td>
                      <td style={{ ...tableStyling, width: "140px" }} className="text-right">
                        {RupiahConvert(String(val.rounded)).detail}
                      </td>
                      <td style={{ ...tableStyling, width: "124px" }} className="text-right">
                        {RupiahConvert(String(val.subtotal)).detail}
                      </td>
                    </tr>
                  );
                })}

              <tr className="bg-light">
                <td colSpan={9} className="text-right p-2" style={tableStyling}>
                  <b>Total: </b>
                </td>
                <td className="text-right text-nowrap" style={tableStyling}>
                  <b>{totalRAB()}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? dataBehavior === "A"
                  ? "APPROVE"
                  : "VERIFY"
                : modalConfig.type == "REV"
                  ? "REVISE"
                  : "REJECT" + " RAB"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                  ? "warning"
                  : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ values, handleChange, validateForm, errors, touched, setTouched, dirty }) => {
    const dataPengaju = dataRAB?.approval ? dataRAB.approval : [];
    const dataDetail = dataRAB.detail ? dataRAB.detail : "-";

    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Rencana Anggaran Biaya</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataApproval.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval == "VER" ? `Pemeriksa ${val.approval_level}` : "Pengesah"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
          <hr />
          {dataPengaju.length > 1 && <hr />}
          <div>
            <TextArea
              label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              {dataBehavior === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject Rencana Anggaran Biaya</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={dataBehavior === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: (
                            <span className="text-success">
                              {dataBehavior === "A" ? "Approve" : "Verify"} Rencana Anggaran Biaya
                            </span>
                          ),
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject Rencana Anggaran Biaya</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REV",
                          title: (
                            <span className="text-warning">Revise Rencana Anggaran Biaya</span>
                          ),
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={dataBehavior === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: (
                            <span className="text-success">
                              {dataBehavior === "A" ? "Approve" : "Verify"} Rencana Anggaran Biaya
                            </span>
                          ),
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const CatatanApproval = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval RAB</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataRAB.stakeholder.map(
              (val, index) =>
                index !== 0 &&
                val.status_approval !== "PEN" && (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === "VER" || val.status_approval === "REV"
                          ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                          : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                      }
                      value1={val.nama_karyawan ?? "-"}
                      title2="Catatan"
                      value2={val.catatan ?? "-"}
                    />
                  </Col>
                )
            )}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const ModalAnalisaSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiRAB
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === "analisa" ? "xl" : "md"}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  useEffect(() => {
    setNavbarTitle("Rencana Anggaran Biaya");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Rencana Anggaran Biaya</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
            </>
          )}

          {!isPageLoading && (
            <TableRAB
              profit={profit}
              prelim={prelim}
              setTotalRAB={setTotalRAB}
              dataListRAB={dataListRAB}
            />
          )}

          {!isPageLoading && (
            <TableLC dataListLC={dataListLC} totalLC={totalLC} setTotalLC={setTotalLC} />
          )}

          {!isPageLoading && (
            <TableMP dataListMP={dataListMP} totalMP={totalMP} setTotalMP={setTotalMP} />
          )}

          {!isPageLoading && (
            <GrandTotalSection totalRAB={totalRAB} totalLC={totalLC} totalMP={totalMP} />
          )}
        </Card.Body>
      </Card>

      {console.log(dataRAB)}

      {!isPageLoading && tab !== "history" && (
        <Formik
          initialValues={{ catatan: "" }}
          onSubmit={(values) => {
            const finalValues = {
              ...values,
              no_transaksi: dataRAB.no_rab,
              status_approval: modalConfig.type,
              tgl_approval: DateConvert(new Date()).default,
              approval_baseline: dataRAB.baseline,
            };

            RABApi.approve(finalValues)
              .then((res) => {
                history.push("/human-resource/approval/rab", {
                  alert: {
                    show: true,
                    variant: "primary",
                    text: `${modalConfig.type == "APP"
                      ? "Approve"
                      : modalConfig.type == "REV"
                        ? "Revise"
                        : "Reject"
                      } data berhasil!`,
                  },
                });
                const logValues = {
                  no_transaksi: dataRAB.no_rab,
                  nama_transaksi: "Peluang",
                  baseline: dataRAB.baseline,
                  status_approval: modalConfig.type,
                  no_transaksi_ref: dataRAB.no_rae,
                  baseline_ref: dataRAB.baseline,
                  keterangan_transaksi:
                    modalConfig.type === "APP"
                      ? "Approve Rencana Anggaran Biaya"
                      : modalConfig.type === "VER"
                        ? "Verifikasi Rencana Anggaran Biaya"
                        : "Reject Rencana Anggaran Biaya",
                };
                RABApi.saveLogProgress(logValues);
              })
              .catch((err) => {
                setAlertConfig({
                  variant: "danger",
                  text: `Ubah data gagal! (${err.response.data.message})`,
                });
              })
              .finally(() => setModalConfig({ show: false }));
          }}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            errors,
            touched,
            validateForm,
            setTouched,
            dirty,
          }) => (
            <>
              {!isPageLoading && (
                <FormCard
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  validateForm={validateForm}
                  setTouched={setTouched}
                  dirty={dirty}
                />
              )}
              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      )}

      {!isPageLoading && tab === "history" && <CatatanApproval />}

      <ModalAnalisaSection
        processedData={processedData}
        dataBarangJadi={dataListRAB}
        setDataBarangJadi={setDataListRAB}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        modalConfig={modalAnalisaConfig}
        setModalConfig={setModalAnalisaConfig}
      />
    </>
  );
};

export default DetailRAB;
