import Services from "../../services";

class SPKApi {
  get(params) {
    return Services.get("/hrdu/approval_spk/list", { params });
  }

  getHistory(params) {
    return Services.get("/hrdu/approval_spk/history/list", { params });
  }

  getSingle(params) {
    return Services.get("/hrdu/approval_spk/detail", { params });
  }

  save(data) {
    return Services.post("/hrdu/approval_spk/approve", data);
  }
  saveLogProgress(data) {
    return Services.post("/hrdu/log_progress/", data);
  }
}

export default new SPKApi();
