import Services from "../../services";

class PeluangApi {
    get(params) {
        return Services.get("/hrdu/approval_peluang/list", { params });
    }

    getHistory(params) {
        return Services.get("/hrdu/approval_peluang/history", { params });
    }

    getSingle(params) {
        return Services.get("/hrdu/approval_peluang/detail", { params });
    }

    getAnalisa(params) {
        return Services.get("/hrdu/approval_peluang/analisa_barang_jadi_single/", { params });
    }

    save(data) {
        return Services.post("/hrdu/approval_peluang/approve", data);
    }
}

export default new PeluangApi();
