import React, {
  useState,
  useEffect
} from 'react'
import {
  useParams
} from 'react-router-dom'
import {
  Alert
} from 'components'
import { RupiahConvert } from 'utilities'

const TableListHardwood = ({ dataKayu, dataHardwood, setDataHardwood }) => {
  const { id } = useParams()
  const [isFormHeader, setIsFormHeader] = useState(false)
  const [processedData, setProcessedData] = useState({})
  const [editedData, setEditedData] = useState({})
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'danger'
  })

  let lastUrutanItem = 1
  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle'
  }

  let subTotalUnit = 0
  let subTotalPrice = 0

  const ListDataTable = ({ index, val, totalUnit, totalPrice, setProcessedData, setModalConfig }) => {
    return (
      <tr key={index}>
        <td className="px-1" style={tableStyling}>
          {val.is_header
            ? <b>{val.deskripsi ? val.deskripsi : '-'}</b>
            : val.deskripsi ? val.deskripsi : '-'
          }
        </td>
        <td className="px-1" style={tableStyling}>
          {val.nama_kayu}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.qty_final}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.t_final}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.w_final}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.l_final}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.qty_raw}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.t_raw}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.w_raw}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.l_raw}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.is_header
            ? ''
            : totalUnit
          }
        </td>
        <td className="text-right text-nowrap" style={tableStyling}>
          {val.unit_price
            ? RupiahConvert(val.unit_price.toString()).detail
            : ''
          }
        </td>
        <td className="text-right" style={tableStyling}>
          {val.konstanta}
        </td>
        <td className="text-right text-nowrap" style={tableStyling}>
          {val.is_header
            ? ''
            : RupiahConvert(totalPrice.toString()).detail
          }
        </td>
      </tr>
    )
  }

  return (
    <>
      <div className="p-2 mt-3">
        <b>List Hardwood</b>
      </div>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({
          ...alertConfig,
          show: false
        })}
      />
      <div>
        <table className="table table-bordered bg-white table-sm">
          <thead className="text-center">
            <tr>
              <th rowSpan={2} className="align-middle" style={tableStyling}>Deskripsi</th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>Spesifikasi Kayu</th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>Qty Final</th>
              <th colSpan={3} className="align-middle" style={tableStyling}>Final (cm)</th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>Qty Raw</th>
              <th colSpan={3} className="align-middle" style={tableStyling}>Raw (cm)</th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>Unit (m3)</th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>Unit Price (Rp)</th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>Konst.</th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>Total Price (Rp)</th>
            </tr>
            <tr>
              <th style={tableStyling}>T</th>
              <th style={tableStyling}>W</th>
              <th style={tableStyling}>L</th>
              <th style={tableStyling}>T</th>
              <th style={tableStyling}>W</th>
              <th style={tableStyling}>L</th>
            </tr>
          </thead>
          <tbody>
            {dataHardwood && dataHardwood.length > 0 && dataHardwood.map((val, index) => {
              const lastItem = parseInt(val.urutan_item) ? parseInt(val.urutan_item) + 1 : 1
              const countTotalUnit = parseFloat((parseFloat(val.qty_raw).toFixed(6) * parseFloat(val.t_raw).toFixed(6) * parseFloat(val.w_raw).toFixed(6) * parseFloat(val.l_raw).toFixed(6)) / 1000000).toFixed(6)
              const totalUnit = parseFloat(countTotalUnit) < parseFloat(0.000001) ? '0.000001' : parseFloat(countTotalUnit)
              const totalPrice = parseInt(parseFloat(totalUnit) * parseInt(val.unit_price) * parseFloat(val.konstanta))
              const checkUnit = val.is_header ? 0 : totalUnit
              const checkPrice = val.is_header ? 0 : totalPrice
              subTotalUnit = parseFloat(parseFloat(subTotalUnit) + parseFloat(checkUnit)).toFixed(6)
              subTotalPrice = parseInt(parseInt(subTotalPrice) + parseInt(checkPrice))
              lastUrutanItem = lastItem

              return (
                <ListDataTable
                  index={index}
                  val={val}
                  totalUnit={totalUnit}
                  totalPrice={totalPrice}
                  editedData={editedData}
                  setEditedData={setEditedData}
                  setProcessedData={setProcessedData}
                  setModalConfig={setModalConfig}
                />
              )
            })}
            <tr>
              <td colSpan={10} className="text-right p-2" style={tableStyling}>
                <b>Total Unit : </b>
              </td>
              <td className="text-right" style={tableStyling}>
                <b>{subTotalUnit}</b>
              </td>
              <td className="text-right p-2" style={tableStyling}>
                <b>Total Price : </b>
              </td>
              <td colSpan={2} className="text-right text-nowrap" style={tableStyling}>
                <b>{RupiahConvert(subTotalPrice.toString()).detail}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TableListHardwood
