import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import htmlParser from "html-react-parser";
import { DataStatus } from "components";
import { RupiahConvert } from "utilities";
import { AnalisaBarangJadiApi, ApprovalRAEApi, PeluangApi } from "api";
import {
  TableListHardwood,
  TableListPlywood,
  TableListTPFS,
  TableListTPLC,
  TableListTPMP,
  TableListTPBOP,
  TableListFNBP,
  TableListFNSC,
  TableListFNLC,
  TableListLCOS,
  TableListMPOS
} from "./Table"
import FilesUpload from "./FilesUpload"

const AnalisaBarangJadiRAE = ({ processedData, dataBarangJadi, setDataBarangJadi, alertConfig, setAlertConfig, modalConfig, setModalConfig, }) => {
  const history = useHistory();
  const id = processedData.id_item_buaso;
  const ref_uid = processedData.ref_uid;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState({});
  const [dataKayu, setDataKayu] = useState([]);
  const [dataKelompokTipeBarangJadi, setDataKelompokTipeBarangJadi] = useState([]);
  const [dataTipeBarangJadi, setDataTipeBarangJadi] = useState([]);
  const [dataFinishingBarangJadi, setDataFinishingBarangJadi] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);

  const [alertModalConfig, setAlertModalConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PeluangApi.getAnalisa({
      id_barang_jadi: id,
      ref_uid: ref_uid ? ref_uid : ''
    })
      .then(barang => {
        setDataAnalisaBarangJadi(barang.data.data)
        setFilesUpload(barang?.data?.data?.gambar ?? [])
      })
      .catch(() => {
        setIsFetchingFailed(true)
        setAlertModalConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!'
        })
      })
      .finally(() => setIsPageLoading(false))
  };

  useEffect(() => {
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>{value}</b>
      </div>
    );

    const InfoItemUraian = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <div>{value}</div>
      </div>
    );

    const InfoItemLink = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>
          <a href={value} target="_blank">
            {value}
          </a>
        </b>
      </div>
    );

    return (
      <Row>
        <Col>
          <InfoItem
            title="Kode Barang Jadi"
            value={dataAnalisaBarangJadi.kode_item ? dataAnalisaBarangJadi.kode_item : "-"}
          />
          <InfoItem
            title="Nama Barang Jadi"
            value={dataAnalisaBarangJadi.nama_item ? dataAnalisaBarangJadi.nama_item : "-"}
          />
          <InfoItem
            title="Jenis Barang Jadi"
            value={dataAnalisaBarangJadi.nama_jenis ? dataAnalisaBarangJadi.nama_jenis : "-"}
          />
          <InfoItem
            title="Satuan Jual"
            value={dataAnalisaBarangJadi.nama_satuan ? dataAnalisaBarangJadi.nama_satuan : "-"}
          />
          {dataAnalisaBarangJadi.link_referensi ? (
            <InfoItemLink title="Link Referensi" value={dataAnalisaBarangJadi.link_referensi} />
          ) : (
            <InfoItem title="Link Referensi" value="-" />
          )}
        </Col>
        <Col>
          <InfoItemUraian title="Uraian" value={htmlParser(dataAnalisaBarangJadi?.uraian ?? "-")} />
        </Col>
      </Row>
    );
  };

  const TableSection = () => {
    const [dataHardwood, setDataHardwood] = useState([]);
    const [dataPlywood, setDataPlywood] = useState([]);
    const [dataTipe, setDataTipe] = useState([]);
    const [dataTPFS, setDataTPFS] = useState([]);
    const [dataTPLC, setDataTPLC] = useState([]);
    const [dataTPMP, setDataTPMP] = useState([]);
    const [dataTPBOP, setDataTPBOP] = useState([]);
    const [dataSelectTPFS, setDataSelectTPFS] = useState([]);
    const [dataSelectTPLC, setDataSelectTPLC] = useState([]);
    const [dataSelectTPMP, setDataSelectTPMP] = useState([]);
    const [dataSelectTPBOP, setDataSelectTPBOP] = useState([]);
    const [dataFNBP, setDataFNBP] = useState([]);
    const [dataFNSC, setDataFNSC] = useState([]);
    const [dataFNLC, setDataFNLC] = useState([]);
    const [dataMPOS, setDataMPOS] = useState([]);
    const [dataLCOS, setDataLCOS] = useState([]);
    const [dataSelectFNBP, setDataSelectFNBP] = useState([]);
    const [dataSelectFNSC, setDataSelectFNSC] = useState([]);
    const [dataSelectFNLC, setDataSelectFNLC] = useState([]);
    const [kelompokTipe, setKelompokTipe] = useState({});
    const [tipeBarang, setTipeBarang] = useState({});
    const [finishingBarang, setFinishingBarang] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState({
      tipe: false,
      finishing: false,
    });

    const filterDataHardwood = () => {
      let finalData = [];
      const data = dataAnalisaBarangJadi.analisa_hardwood;
      const sorting = data.sort((a, b) => {
        if (a.urutan_item < b.urutan_item) return -1;
        if (a.urutan_item > b.urutan_item) return 1;

        return 0;
      });

      sorting.map((val) => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          nama_kayu: val.nama_kayu,
          id_kayu: val.id_kayu,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
        });
      });

      setDataHardwood(finalData);
    };

    const filterDataPlywood = () => {
      let finalData = [];
      const data = dataAnalisaBarangJadi.analisa_plywood;
      const sorting = data.sort((a, b) => {
        if (a.urutan_item < b.urutan_item) return -1;
        if (a.urutan_item > b.urutan_item) return 1;

        return 0;
      });

      sorting.map((val) => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          nama_kayu: val.nama_kayu,
          id_kayu: val.id_kayu,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
        });
      });

      setDataPlywood(finalData);
    };

    const filterDataTipeBarangJadi = () => {
      const filterData = (data) => {
        const newData = [];
        data &&
          data.length > 0 &&
          data.map((val, index) => {
            newData.push({
              id_barang_jadi: val.id_barang_jadi,
              id_item_buaso: val.id_item_bahan,
              qty: val.qty,
              unit_price: val.harga_satuan,
              konstanta: val.koefisien,
              kode_item_bahan: val.kode_item_bahan,
              nama_item: val.nama_item,
              nama_satuan: val.nama_satuan,
              urutan_item: index + 1,
            });
          });
        return newData;
      };

      const newDataTPFS = filterData(dataAnalisaBarangJadi.analisa_fin_tp_fs);
      const newDataTPLC = filterData(dataAnalisaBarangJadi.analisa_fin_tp_lc);
      const newDataTPMP = filterData(dataAnalisaBarangJadi.analisa_fin_tp_mp);
      const newDataTPBOP = filterData(dataAnalisaBarangJadi.analisa_fin_tp_bop);
      const newDataLCOS = filterData(dataAnalisaBarangJadi.analisa_fin_os_lc);
      const newDataMPOS = filterData(dataAnalisaBarangJadi.analisa_fin_os_mp);


      setDataTPFS(newDataTPFS);
      setDataTPLC(newDataTPLC);
      setDataTPMP(newDataTPMP);
      setDataTPBOP(newDataTPBOP);
      setDataLCOS(newDataLCOS);
      setDataMPOS(newDataMPOS);
    };

    const filterDataFinishingBarangJadi = () => {
      const filterData = (data) => {
        const newData = [];
        data &&
          data.length > 0 &&
          data.map((val, index) => {
            newData.push({
              id_barang_jadi: val.id_barang_jadi,
              id_item_bahan: val.id_item_bahan,
              qty: val.qty,
              unit_price: val.harga_satuan,
              konstanta: val.koefisien,
              kode_item_bahan: val.kode_item_bahan,
              nama_item: val.nama_item,
              nama_satuan: val.nama_satuan,
              urutan_item: index + 1,
            });
          });
        return newData;
      };

      const newDataFNBP = filterData(dataAnalisaBarangJadi.analisa_fin_fn_bp);
      const newDataFNSC = filterData(dataAnalisaBarangJadi.analisa_fin_fn_sc);
      const newDataFNLC = filterData(dataAnalisaBarangJadi.analisa_fin_fn_lc);

      setDataFNBP(newDataFNBP);
      setDataFNSC(newDataFNSC);
      setDataFNLC(newDataFNLC);
    };

    const mappingHardwood = () => {
      const final = [];

      dataHardwood.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          id_kayu: val.id_kayu,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
        });
      });

      return final;
    };

    const mappingPlywood = () => {
      const final = [];

      dataPlywood.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          id_kayu: val.id_kayu,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
        });
      });

      return final;
    };

    const mappingTP = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_buaso,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
        });
      });

      return final;
    };

    const mappingFN = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_bahan,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
        });
      });

      return final;
    };

    const getGrandTotal = () => {
      let totalHardwood = 0;
      let totalPlywood = 0;
      let totalTPFS = 0;
      let totalTPLC = 0;
      let totalTPMP = 0;
      let totalTPBOP = 0;
      let totalFNBP = 0;
      let totalFNSC = 0;
      let totalFNLC = 0;
      let totalLCOS = 0;
      let totalMPOS = 0;

      dataHardwood.map((val) => {
        const countTotalUnit = parseFloat(
          (parseFloat(val.qty_raw).toFixed(6) *
            parseFloat(val.t_raw).toFixed(6) *
            parseFloat(val.w_raw).toFixed(6) *
            parseFloat(val.l_raw).toFixed(6)) /
          1000000
        ).toFixed(6);
        const totalUnit =
          parseFloat(countTotalUnit) < parseFloat(0.01)
            ? parseFloat(0.01)
            : parseFloat(countTotalUnit);
        const totalPrice = parseInt(
          parseFloat(totalUnit) * parseInt(val.unit_price) * parseFloat(val.konstanta)
        );
        const checkPrice = val.is_header ? 0 : totalPrice;
        totalHardwood = parseInt(parseInt(totalHardwood) + parseInt(checkPrice));
      });

      dataPlywood.map((val) => {
        const countTotalUnit = parseFloat(
          (parseFloat(val.qty_raw).toFixed(6) *
            parseFloat(val.t_raw).toFixed(6) *
            parseFloat(val.w_raw).toFixed(6) *
            parseFloat(val.l_raw).toFixed(6)) /
          1000000
        ).toFixed(6);
        const totalUnit =
          parseFloat(countTotalUnit) < parseFloat(0.01)
            ? parseFloat(0.01)
            : parseFloat(countTotalUnit).toFixed(6);
        const totalPrice = parseInt(
          parseFloat(totalUnit) * parseInt(val.unit_price) * parseFloat(val.konstanta)
        );
        const checkPrice = val.is_header ? 0 : totalPrice;
        totalPlywood = parseInt(parseInt(totalPlywood) + parseInt(checkPrice));
      });

      dataTPFS.map((val) => {
        const total = parseInt(
          parseFloat(val.qty).toFixed(2) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(2)
        );
        totalTPFS = parseInt(totalTPFS) + parseInt(total);
      });

      dataTPLC.map((val) => {
        const total = parseInt(
          parseFloat(val.qty).toFixed(2) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(2)
        );
        totalTPLC = parseInt(totalTPLC) + parseInt(total);
      });

      dataTPMP.map((val) => {
        const total = parseInt(
          parseFloat(val.qty).toFixed(2) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(2)
        );
        totalTPMP = parseInt(totalTPMP) + parseInt(total);
      });

      dataTPBOP.map((val) => {
        const total = parseInt(
          parseFloat(val.qty).toFixed(2) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(2)
        );
        totalTPBOP = parseInt(totalTPBOP) + parseInt(total);
      });

      dataFNBP.map((val) => {
        const total = parseInt(
          parseFloat(val.qty).toFixed(2) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(2)
        );
        totalFNBP = parseInt(totalFNBP) + parseInt(total);
      });

      dataFNSC.map((val) => {
        const total = parseInt(
          parseFloat(val.qty).toFixed(2) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(2)
        );
        totalFNSC = parseInt(totalFNSC) + parseInt(total);
      });

      dataFNLC.map((val) => {
        const total = parseInt(
          parseFloat(val.qty).toFixed(2) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(2)
        );
        totalFNLC = parseInt(totalFNLC) + parseInt(total);
      });

      // dataLCOS.map((val) => {
      //   const total = parseInt(
      //     parseFloat(val.qty).toFixed(2) *
      //     parseInt(val.unit_price) *
      //     parseFloat(val.konstanta).toFixed(2)
      //   );
      //   totalLCOS = parseInt(totalLCOS) + parseInt(total);
      // });

      // dataMPOS.map((val) => {
      //   const total = parseInt(
      //     parseFloat(val.qty).toFixed(2) *
      //     parseInt(val.unit_price) *
      //     parseFloat(val.konstanta).toFixed(2)
      //   );
      //   totalMPOS = parseInt(totalMPOS) + parseInt(total);
      // });

      const grandTotal =
        parseInt(totalHardwood) +
        parseInt(totalPlywood) +
        parseInt(totalTPFS) +
        parseInt(totalTPLC) +
        parseInt(totalTPMP) +
        parseInt(totalTPBOP) +
        parseInt(totalFNBP) +
        parseInt(totalFNSC) +
        parseInt(totalFNLC) +
        parseInt(totalLCOS) +
        parseInt(totalMPOS);

      console.log('totalHardwood', totalHardwood)
      console.log('totalPlyWood', totalPlywood)

      return RupiahConvert(grandTotal.toString()).detail;
    };

    const submitDataHandler = () => {
      setIsSubmitting(true);

      const analisaHardwood = mappingHardwood();
      const analisaPlywood = mappingPlywood();
      const analisaFinTpFs = mappingTP(dataTPFS);
      const analisaFinTpLc = mappingTP(dataTPLC);
      const analisaFinTpMp = mappingTP(dataTPMP);
      const analisaFinTpBop = mappingTP(dataTPBOP);
      const analisaFinFnBp = mappingFN(dataFNBP);
      const analisaFinFnSc = mappingFN(dataFNSC);
      const analisaFinFnLc = mappingFN(dataFNLC);

      const finalValues = ref_uid
        ? {
          id_barang_jadi: id,
          ref_uid: ref_uid,
          analisa_hardwood: analisaHardwood,
          analisa_plywood: analisaPlywood,
          analisa_fin_tp_fs: analisaFinTpFs,
          analisa_fin_tp_lc: analisaFinTpLc,
          analisa_fin_tp_mp: analisaFinTpMp,
          analisa_fin_tp_bop: analisaFinTpBop,
          analisa_fin_fn_bp: analisaFinFnBp,
          analisa_fin_fn_sc: analisaFinFnSc,
          analisa_fin_fn_lc: analisaFinFnLc,
        }
        : {
          id_barang_jadi: id,
          analisa_hardwood: analisaHardwood,
          analisa_plywood: analisaPlywood,
          analisa_fin_tp_fs: analisaFinTpFs,
          analisa_fin_tp_lc: analisaFinTpLc,
          analisa_fin_tp_mp: analisaFinTpMp,
          analisa_fin_tp_bop: analisaFinTpBop,
          analisa_fin_fn_bp: analisaFinFnBp,
          analisa_fin_fn_sc: analisaFinFnSc,
          analisa_fin_fn_lc: analisaFinFnLc,
        };

      AnalisaBarangJadiApi.save(finalValues)
        .then((res) => {
          const ref = res.data.data.ref_uid;
          const addRef = {
            ...processedData,
            harga_satuan_rae: RupiahConvert(getGrandTotal().toString()).default,
            ref_uid: ref,
          };
          const finalData = dataBarangJadi.map((val, index) =>
            index.toString() === processedData.index.toString() ? addRef : val
          );

          setDataBarangJadi(finalData);

          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Referensi analisa barang jadi berhasil disimpan!",
          });
        })
        .catch(() =>
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Referensi analisa barang jadi gagal disimpan!",
          })
        )
        .finally(() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        );
    };

    useEffect(() => {
      filterDataHardwood();
      filterDataPlywood();
      filterDataTipeBarangJadi();
      filterDataFinishingBarangJadi();
    }, []);

    const GrandTotalSection = () => (
      <div className="p-2 bg-white text-right border">
        <b className="text-nowrap pr-3" style={{ fontSize: "14px" }}>
          Grand Total : {getGrandTotal()}
        </b>
      </div>
    );

    return (
      <div>
        <TableListHardwood
          dataKayu={dataKayu.filter((kayu) => kayu.tipe_kayu.toUpperCase() === "HARDWOOD")}
          dataHardwood={dataHardwood}
          setDataHardwood={setDataHardwood}
        />
        <TableListPlywood
          dataKayu={dataKayu.filter((kayu) => kayu.tipe_kayu.toUpperCase() === "PLYWOOD")}
          dataPlywood={dataPlywood}
          setDataPlywood={setDataPlywood}
        />
        <hr />
        <TableListTPFS
          dataSelectTPFS={dataSelectTPFS}
          dataTPFS={dataTPFS}
          setDataTPFS={setDataTPFS}
        />
        <TableListTPLC
          dataSelectTPLC={dataSelectTPLC}
          dataTPLC={dataTPLC}
          setDataTPLC={setDataTPLC}
        />
        <TableListTPMP
          dataSelectTPMP={dataSelectTPMP}
          dataTPMP={dataTPMP}
          setDataTPMP={setDataTPMP}
        />
        <TableListTPBOP
          dataSelectTPBOP={dataSelectTPBOP}
          dataTPBOP={dataTPBOP}
          setDataTPBOP={setDataTPBOP}
        />
        <hr />
        <TableListFNBP
          dataSelectFNBP={dataSelectFNBP}
          dataFNBP={dataFNBP}
          setDataFNBP={setDataFNBP}
        />
        <TableListFNSC
          dataSelectFNSC={dataSelectFNSC}
          dataFNSC={dataFNSC}
          setDataFNSC={setDataFNSC}
        />
        <TableListFNLC
          dataSelectFNLC={dataSelectFNLC}
          dataFNLC={dataFNLC}
          setDataFNLC={setDataFNLC}
        />

        {/* <TableListLCOS
          dataSelectTPLC={dataSelectTPLC}
          dataLCOS={dataLCOS}
          setDataLCOS={setDataLCOS}
        />
        <TableListMPOS
          dataSelectTPMP={dataSelectTPMP}
          dataMPOS={dataMPOS}
          setDataMPOS={setDataMPOS}
        /> */}
        <GrandTotalSection />
        {/* <VeiwRef data={dataAnalisaBarangJadi} /> */}
      </div>
    );
  };

  return (
    <>
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : isFetchingFailed ? (
        <DataStatus text="Data gagal dimuat!" />
      ) : (
        <>
          <InfoSection />
          <FilesUpload filesUpload={filesUpload} setFilesUpload={setFilesUpload} action="detail" />
          <TableSection />
        </>
      )}
    </>
  );
};

export default AnalisaBarangJadiRAE;
