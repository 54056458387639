import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  Checkbox,
  FilterButton
} from "components";
import { DateConvert, PageNumber as TableNumber, RupiahConvert } from "utilities";
import { ApprovalSeleksiVendorApi } from "api";
import { AiOutlineEye } from "react-icons/ai"
import { ModalFilterSV } from "../Comps";

const ApprovalSeleksiVendor = () => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  const [modalFilter, setModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.sv?.filter?.active,
      jabatan: location?.state?.sv?.filter?.jabatan,
      pengaju: location?.state?.sv?.filter?.pengaju,
      tgl_seleksi_vendor_mulai: location?.state?.sv?.filter?.tgl_seleksi_vendor_mulai,
      tgl_seleksi_vendor_selesai: location?.state?.sv?.filter?.tgl_seleksi_vendor_selesai,
      tgl_purchase_request_mulai: location?.state?.sv?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai: location?.state?.sv?.filter?.tgl_purchase_request_selesai,
      vendor: location?.state?.sv?.filter?.vendor,
      id_item: location?.state?.sv?.filter?.id_item,
      satuan: location?.state?.sv?.filter?.satuan,
    },
    pagination: {
      page: location?.state?.sv?.filter?.page ?? "1",
      dataLength: location?.state?.sv?.filter?.dataLength ?? "10",
      totalPage: location?.state?.sv?.filter?.totalPage ?? "1",
      dataCount: location?.state?.sv?.filter?.dataCount ?? "0",
    }
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    ApprovalSeleksiVendorApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      jabatan: dataFilter?.filter?.jabatan,
      pengaju: dataFilter?.filter?.pengaju,
      tgl_seleksi_vendor_mulai: dataFilter?.filter?.tgl_seleksi_vendor_mulai,
      tgl_seleksi_vendor_selesai: dataFilter?.filter?.tgl_seleksi_vendor_selesai,
      tgl_purchase_request_mulai: dataFilter?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai: dataFilter?.filter?.tgl_purchase_request_selesai,
      vendor: dataFilter?.filter?.vendor,
      id_item: dataFilter?.filter?.id_item,
      satuan: dataFilter?.filter?.satuan,
    })
      .then(data => {
        const dataSeleksiVendor = data.data.data;
        setDataSeleksiVendor(dataSeleksiVendor ? dataSeleksiVendor : []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
        searchConfig.status &&
          setAlertConfig({
            show: true,
            variant: "primary",
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const generateStatus = (status) => {
    if (status.toUpperCase() === "REV") {
      return "REVISI";
    }

    if (status.toUpperCase() === "VER") {
      return "VERIFIED";
    }

    if (status.toUpperCase() === "APP") {
      return "APPROVED";
    }

    return "PENDING";
  };

  useEffect(() => {
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    searchConfig.key, 
    dataFilter?.pagination?.page, 
    dataFilter?.pagination?.dataLength, 
    dataFilter?.filter?.active,
    dataFilter?.filter?.jabatan,
    dataFilter?.filter?.pengaju,
    dataFilter?.filter?.tgl_seleksi_vendor_mulai,
    dataFilter?.filter?.tgl_seleksi_vendor_selesai,
    dataFilter?.filter?.tgl_purchase_request_mulai,
    dataFilter?.filter?.tgl_purchase_request_selesai,
    dataFilter?.filter?.vendor,
    dataFilter?.filter?.id_item,
    dataFilter?.filter?.satuan,
  ]);

  const PageContent = () => {
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return <li key={index} index={index}>{`${brg.nama_item} ${brg.qty ? brg.qty : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>;
              } else {
                return index <= 1 && <li key={index} index={index}>{`${brg.nama_item} ${brg.qty ? brg.qty : ""} ${brg.nama_satuan ? brg.nama_satuan : ""}`?? "-"}</li>;
              }
            })}
          </ul>
          {data.length > 2 
            ? ( <div
                  className="text-primary"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    // fontWeight: "bold",
                  }}
                  onClick={() => {
                    setisReadMoreClick((prev) => !prev);
                  }}
                >
                  {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
              </div> ) : ("")}
        </>
      );
    }
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th style={{minWidth:"300px"}}>Informasi Pembuat Pengajuan</Th>
            <ThFixed>Informasi Seleksi Vendor</ThFixed>
            <ThFixed>Informasi Purchase Request</ThFixed>
            <Th style={{minWidth:"250px"}}>Vendor</Th>
            <Th style={{minWidth:"350px"}}>Item Seleksi Vendor</Th>
            <Th style={{minWidth:"150px"}}>Satuan Beli</Th>
            <Th style={{minWidth:"100px"}}>Qty. Seleksi Vendor</Th>
            <Th style={{minWidth:"100px"}}>Harga Kesepakan</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataSeleksiVendor.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
              <Td>
                <div className="text-left">{val.nama_karyawan_pengaju ?? "-"}</div>
                <div className="text-left">{val.nama_jabatan_pengaju ?? "-"}</div>
              </Td>
              <TdFixed>
                <div className="text-left">{val.tgl_seleksi_vendor ? DateConvert(new Date(val.tgl_seleksi_vendor)).defaultDMY : "-"}</div>
                <div className="text-left">{val.no_seleksi_vendor ?? "-"}</div>
              </TdFixed>
              <TdFixed>
                <div className="text-left">{val.tgl_purchase_request ? DateConvert(new Date(val.tgl_purchase_request)).defaultDMY : "-"}</div>
                <div className="text-left">{val.no_purchase_request ?? "-"}</div>
              </TdFixed>
              <Td>{val.nama_vendor ?? "-"}</Td>
              <Td>{val.nama_item ?? "-"}</Td>
              <Td>{val.satuan_beli ?? "-"}</Td>
              <Td textRight>{val.qty_order ? parseFloat(val.qty_order).toPrecision() : "0"}</Td>
              <Td textRight>{val.harga_kesepakatan ? RupiahConvert(val.harga_kesepakatan.toString()).detail : "Rp.0"}</Td>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ActionButton
                    size="sm"
                    text={<AiOutlineEye className="text-dark" style={{fontSize:"16px"}} />}
                    className="col"
                    onClick={() =>
                      history.push("/human-resource/approval/seleksi-vendor/detail", { 
                        no_seleksi_vendor: val.no_seleksi_vendor,
                        ...location?.state, sv: dataFilter
                      })
                    }
                  />
                </div>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={(e) => {
                  const key = e.target.value;
                  setSearchConfig({
                    ...searchConfig,
                    key: e.target.value,
                  });
                  setAlertConfig({
                    show: key ? true : false,
                    variant: "primary",
                    text: "Hasil dari pencarian: " + key,
                  });
                }}
              />
            </Col>
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading 
        ? <DataStatus loading={true} text="Memuat data . . ." />
        : dataSeleksiVendor 
          ? dataSeleksiVendor.length > 0 
            ? <PageContent />
            : <DataStatus text="Tidak ada data" />
          : <DataStatus text="Data gagal dimuat" />
      }

      {modalFilter && (
        <ModalFilterSV
          type="app"
          modalFilter={modalFilter}
          setModalFilter={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default ApprovalSeleksiVendor;
