import React, {
  useState,
  useEffect
} from 'react'
import {
  Row,
  Col,
  Modal
} from 'react-bootstrap'
import {
  IoTrashOutline,
  IoPencilOutline,
  IoOptionsOutline
} from 'react-icons/io5'
import {
  useFormik
} from 'formik'
import * as Yup from 'yup'
import {
  Alert,
  SelectSearch,
  Input,
  ActionButton
} from '../../../../../components'
import {
  RupiahConvert
} from '../../../../../utilities'
import AnalisaBarangJadiPeluang from './AnalisaBarangJadiPeluang'

const TableBarangJadi = ({ dataBarangJadi, dataSelectJenisBarangJadi, dataSelectAnalisaBarangJadi, setDataBarangJadi }) => {
  const [processedData, setProcessedData] = useState({})
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: ''
  })

  const TableSection = () => {
    const generateTotal = () => {
      const grandTotal = dataBarangJadi.reduce((prevValue, currentValue) => {
        const checkQty = currentValue.qty_rae ? parseFloat(currentValue.qty_rae) : '0'
        const checkSatuan = currentValue.harga_satuan_rae ? parseInt(currentValue.harga_satuan_rae) : '0'
        const subTotal = parseInt(checkQty * checkSatuan)
        const total = parseInt(prevValue + subTotal)

        return total
      }, 0)

      return RupiahConvert(grandTotal.toString()).detail
    }

    return (
      <>
        <div className="py-2 p-1">
          <b>List Barang Jadi</b>
        </div>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => setAlertConfig({
            ...alertConfig,
            show: false
          })}
        />
        <table className="table table-sm table-bordered" style={{ fontSize: '14px' }}>
          <thead className="text-center bg-light">
            <tr>
              <th className="align-middle" style={{ width: '10px' }}>No</th>
              <th className="align-middle" style={{ width: '100px' }}>Kode Barang Jadi</th>
              <th className="align-middle" style={{ width: '300px' }}>Barang Jadi</th>
              <th className="align-middle">Jenis Barang Jadi</th>
              <th className="align-middle">Satuan</th>
              <th className="align-middle">Qty</th>
            </tr>
          </thead>
          <tbody>
            {dataBarangJadi?.map((val, index) => {
              const checkQty = val.qty_rae ? parseFloat(val.qty_rae) : '0'
              const checkSatuan = val.harga_satuan_rae ? parseInt(val.harga_satuan_rae) : '0'
              const subTotal = parseInt(checkQty * checkSatuan)

              return (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>{val.kode_item}</td>
                  <td>
                    <a href="#" onClick={e => {
                      e.preventDefault()
                      setProcessedData({
                        ...val,
                        index: index
                      })
                      setModalConfig({
                        show: true,
                        type: 'analisa',
                        title: (<span className="text-primary">Analisa Barang Jadi Peluang</span>)
                      })
                    }}>
                      {val.nama_item}
                    </a>
                  </td>
                  <td>{val.nama_jenis ?? "-"}</td>
                  <td>{val.nama_satuan_jual ?? "-"}</td>
                  <td className="text-right">{parseFloat(val.qty ?? 0).toPrecision()}</td>

                  {/* <td className="text-right">{RupiahConvert(parseInt(val.harga_satuan_rae).toString()).detail}</td>
                  <td className="text-right">{RupiahConvert(subTotal?.toString()).detail}</td> */}
                </tr>
              )
            })}
            {/* <tr>
              <td colSpan={5} className="text-right">
                <b>Total :</b>
              </td>
              <td className="text-right">
                <b>{generateTotal()}</b>
              </td>
            </tr> */}
          </tbody>
        </table>
      </>
    )
  }

  const ModalSection = ({ processedData, dataBarangJadi, setDataBarangJadi, alertConfig, setAlertConfig, modalConfig, setModalConfig }) => {
    const AnalisaBarangJadiModal = ({ processedData, dataBarangJadi, setDataBarangJadi, alertConfig, setAlertConfig, modalConfig, setModalConfig }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiPeluang
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      )
    }

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() => setModalConfig({
          ...modalConfig,
          show: false
        })}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>
              {modalConfig.title}
            </small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    )
  }

  return (
    <>
      <TableSection
        dataBarangJadi={dataBarangJadi}
        setDataBarangJadi={setDataBarangJadi}
      />
      <ModalSection
        processedData={processedData}
        dataBarangJadi={dataBarangJadi}
        setDataBarangJadi={setDataBarangJadi}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
      />
    </>
  )
}

export default TableBarangJadi
