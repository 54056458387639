import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  BackButton,
  ActionButton,
  TextArea,
  CRUDLayout,
  Tr,
  THead,
  TBody,
  Td,
  Th,
  Table,
  TdFixed,
  ThFixed,
  DataStatus
} from "../../../components";
import { RupiahConvert } from "../../../utilities";
import { ComplimentApi } from "api";
import InfoComplimentSection from "./components/InfoComplimentSection";

const DetailCompliment = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const no_compliment = state?.no_compliment;
  const tab = state?.tab ?? "";
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataCompliment, setDataCompliment] = useState([]);
  const [approveStatus, setApproveStatus] = useState("V");
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    ComplimentApi.getSingle({ no_compliment: no_compliment })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : "V";
        setDataCompliment(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };
  const whenNoSuratJalan = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };
  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);
    const checkStatus = () => {
      if (modalConfig.type === "revise") {
        return "REV";
      }
      if (modalConfig.type === "approve") {
        return "APP";
      }
      if (modalConfig.type === "reject") {
        return "REJ";
      }
    };
    const finalValues = {
      no_transaksi: dataCompliment.detail.no_transaksi,
      status_approval: checkStatus(),
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataCompliment.detail.baseline,
    };

    ComplimentApi.approve(finalValues)
      .then(() =>
        history.push("/human-resource/approval/compliment", {
          alert: {
            show: true,
            variant: "primary",
            text: `Approval berhasil disimpan!`,
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Approval gagal disimpan!",
        })
      )
      .finally(() => {
        setModalConfig({ ...modalConfig, show: false });
        getInitialData();
      });
  };

  const total = dataCompliment?.detail?.detail?.reduce((acc, val) => Math.round(acc + parseFloat(val?.qty_compliment || 0) * parseFloat(val?.harga_satuan || 0)), 0)

  useEffect(() => {
    setNavbarTitle("Approval Compliment");
    getInitialData();
    no_compliment ? getInitialData() : whenNoSuratJalan();
    return () => {setIsPageLoading(false)};
  }, []);
  
  const TableComplimentSection = () => {
    const dataTableCompliment = dataCompliment?.detail?.detail ? dataCompliment.detail.detail : [];

    return (
      <>
        <Table bordered hovered size="sm">
          <THead className="text-center">
            <Tr>
              <ThFixed>No</ThFixed>
              <Th className="text-center" style={{ width: 110 }}>Kode Barang</Th>
              <Th className="text-center">Item Barang</Th>
              <Th className="text-center" style={{ width: 150 }}>Gudang</Th>
              <Th className="text-center" style={{ width: 110 }}>Satuan</Th>
              <Th className="text-center" style={{ width: 110 }}>Qty. Compliment</Th>
              <Th className="text-center" style={{ width: 160 }}>Harga Satuan</Th>
              <Th className="text-center" style={{ width: 160 }}>Sub total</Th>
            </Tr>
          </THead>
          <TBody>
            {dataTableCompliment.length > 0 ? (
              dataTableCompliment.map((val, index) => {
                const sub_total = Math.round(parseFloat(val?.qty_compliment || 0) * parseFloat(val.harga_satuan || 0))
                return (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <Td>{val.kode_item ?? "-"}</Td>
                    <Td>{val.nama_item ?? "-"}</Td>
                    <Td>{val.nama_gudang ?? "-"}</Td>
                    <Td>{val.nama_satuan ?? "-"}</Td>
                    <Td><div className="text-right">{val.qty_compliment ? parseFloat(val.qty_compliment) : 0}</div></Td>
                    <Td><div className="text-right">{val.harga_satuan ? RupiahConvert(parseInt(val.harga_satuan).toString()).getWithComa : "Rp. 0"}</div></Td>
                    <Td><div className="text-right">{sub_total ? RupiahConvert(parseInt(sub_total).toString()).getWithComa : "Rp. 0"}</div></Td>
                  </Tr>
                )}
              )
            ) : (
              <Tr>
                <Td colSpan={8} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </Td>
              </Tr>
            )}
            <Tr>
              <Td colSpan={7} textRight className="align-middle py-2 font-weight-bold text-right"><b>Total</b></Td>
              <Td textRight className="align-middle py-2 font-weight-bold text-right">{total ? RupiahConvert(parseInt(total || 0).toString()).getWithComa : "Rp.0"}</Td>
            </Tr>
          </TBody>
        </Table>
      </>
    );
  };
  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <h6>
            <b>
              {modalConfig.title === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.title}{" "}
              dengan catatan :
            </b>
          </h6>
          <span>{values.catatan}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type
            }
            variant={
              modalConfig.type === "approve"
                ? "success"
                : modalConfig.type === "revise"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };
  const FormCardSection = ({ formik }) => {
    const { values, errors, touched, dirty, validateForm, handleChange } = formik;
    const dataPengaju = dataCompliment?.approval ? dataCompliment.approval : [];

    return (
      <>
        {tab !== "history" && (
          <div className="mt-2">
            <span style={{ fontSize: "14px" }}>
              <b>Catatan Approval Compliment</b>
            </span>
          </div>
        )}
        {dataPengaju.length > 1 && <hr />}
        {tab !== "history" && (
          <div>
            <TextArea
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
              rows={4}
            />
            <div className="d-flex justify-content-end mt-3">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "reject",
                          title: <span className="text-danger">Reject Detail Surat Jalan</span>,
                        });
                      }

                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "approve",
                          title: (
                            <span className="text-success">
                              {approveStatus === "A" ? "Approve" : "Verify"} Detail Surat Jalan
                            </span>
                          ),
                        });
                      }

                      validateForm();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "reject",
                          title: <span className="text-danger">Reject Detail Surat Jalan</span>,
                        });
                      }

                      validateForm();
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "revise",
                          title: <span className="text-warning">Revise Detail Surat Jalan</span>,
                        });
                      }

                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "approve",
                          title: (
                            <span className="text-success">
                              {approveStatus === "A" ? "Approve" : "Verify"} Detail Surat Jalan
                            </span>
                          ),
                        });
                      }

                      validateForm();
                    }}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  };
  const CatatanApproval = () => {
    const InfoItem = ({ labelHeader, karyawanName, labelCatatan, catatanValue }) => (
      <>
        <small>{labelHeader}</small>
        <p>
          <b>{karyawanName}</b>
        </p>
        <small>{labelCatatan}</small>
        <p>
          <b>{catatanValue}</b>
        </p>
      </>
    );

    return (
      <Card className="mb-4">
        <Card.Body>
          <Row>
            {dataCompliment?.detail?.stakeholder === undefined ? (
              <Col className="text-center">
                <small>Memuat Data...</small>
              </Col>
            ) : (
              dataCompliment?.detail?.stakeholder.length > 0
              ?
              dataCompliment?.detail?.stakeholder.map((val, index) => (
                <>
                  <Col md={3} key={index}>
                    <InfoItem
                      labelHeader={val.status_approval === "APP"
                        ? "Pengesah"
                        : index === 0
                        ? "Pengaju"
                        : `Pemeriksa ${ val.approval_level !== "0" ? val.approval_level : "" }`
                      }
                      karyawanName={val.nama_karyawan ?? "-"}
                      labelCatatan={
                        val.status_approval === "APP"
                          ? "Catatan Pengesah"
                          : index !== 0
                          ? `Catatan Pemeriksa ${ val.approval_level !== "0" ? val.approval_level : "" }` : "" }
                      catatanValue={index !== 0 ? val.catatan : ""}
                    />
                  </Col>
                </>
              ))
              : <div className="mx-auto"><small>Tidak Ada Data</small></div>
            )}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <div className="font-weight-bold mb-2">Detail Data Compliment</div>
      <InfoComplimentSection className='mb-3' data={dataCompliment?.detail} />

      <div className="mt-4" style={{ fontSize: "14px" }}><b>Item Compliment</b></div>
      <TableComplimentSection />

      {approveStatus === "A" &&
        <>
          <div className="mt-2" style={{ fontSize: "14px" }}><b>History Catatan Approval Compliment</b></div>
          <CatatanApproval />
        </>
      }
      {tab === 'history' &&
        <>
          <div className="mt-2" style={{ fontSize: "14px" }}><b>History Catatan Approval Compliment</b></div>
          <CatatanApproval />
        </>
      }

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {isPageLoading 
        ? (<DataStatus loading={true} text="Memuat data . . ." />) 
        : isFetchingFailed 
          ? (<DataStatus text="Data gagal dimuat!" />) 
          : (<>
                {!isFetchingFailed && !isPageLoading && (
                  <Formik
                    initialValues={{ catatan: "" }}
                    validationSchema={Yup.object().shape({catatan: Yup.string().required("Masukan catatan")})}
                    onSubmit={formSubmitHandler}>

                    {(formik) => (
                      <>
                        <FormCardSection formik={formik} />
                        <ModalSection formik={formik} />
                      </>
                    )}
                  </Formik>
                )}
              </>
            )
      }
    </CRUDLayout>
  );
};

export default DetailCompliment;