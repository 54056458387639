import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
    CRUDLayout,
    Alert,
    DataStatus,
    ActionButton,
    ReadButton,
    UpdateButton,
    InputSearch,
    Pagination,
    Table,
    THead,
    TBody,
    Tr,
    ThFixed,
    TdFixed,
    Th,
    Td,
    Checkbox,
} from "components";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { PPAApi } from "api";

const HistoryApprovalPPA = () => {
    const history = useHistory();
    const location = useLocation();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [dataPPA, setDataPPA] = useState([]);
    const [paginationConfig, setPaginationConfig] = useState({
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
    });
    const [searchConfig, setSearchConfig] = useState({
        status: false,
        key: "",
    });
    const [alertConfig, setAlertConfig] = useState({
        show: false,
        variant: "primary",
        text: "",
    });

    const getInitialData = () => {
        setIsPageLoading(true);

        PPAApi.getHistory({
            q: searchConfig.key,
            page: paginationConfig.page,
            per_page: paginationConfig.dataLength,
        })
            .then(({ data }) => {
                console.log('data', data)
                setDataPPA(data?.data ?? []);
                setPaginationConfig({
                    ...paginationConfig,
                    dataCount: data.data_count,
                    totalPage: data.total_page,
                });
                searchConfig.status &&
                    setAlertConfig({
                        show: true,
                        variant: "primary",
                        text: `Hasil dari pencarian: ${searchConfig.key}`,
                    });
            })
            .catch((err) => {
                setAlertConfig({
                    show: true,
                    variant: "danger",
                    text: "Data gagal dimuat!",
                });
            })
            .finally(() => setIsPageLoading(false));
    };

    const checkAlert = () => {
        const locationState = location.state;

        if (locationState) {
            if (locationState.alert) {
                setAlertConfig({
                    show: locationState.alert.show,
                    text: locationState.alert.text,
                    variant: locationState.alert.variant,
                });
            }
        }
    };

    const getStatusApprovalLabel = (status_approval) => {
        switch (status_approval) {
            case "APP":
                return "APPROVED";
            case "REJ":
                return "REJECT";
            case "REV":
                return "REVISI";
            case "VER":
                return "VERIFIED";
            default:
                return "PENDING";
        }
    };

    useEffect(() => {
        getInitialData();
        checkAlert();

        return () => {
            setIsPageLoading(false);
        };
    }, [searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

    const PageContent = () => {
        const DataTable = () => (
            <Table>
                <THead>
                    <Tr>
                        <ThFixed>No</ThFixed>
                        <Th>Pembuat Pengajuan</Th>
                        <Th>Jabatan Pembuat Pengajuan</Th>
                        <ThFixed>Tgl. PPA</ThFixed>
                        <ThFixed>Nomor PPA</ThFixed>
                        <Th>Sumber Daya</Th>
                        <Th>Unit Organisasi</Th>
                        <ThFixed>Status Approval</ThFixed>
                        <Th style={{ width: "8.5rem" }}>Detail Pengajuan</Th>
                    </Tr>
                </THead>
                <TBody>
                    {dataPPA.map((val, index) => (
                        <Tr key={index}>
                            <TdFixed>
                                {TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}
                            </TdFixed>
                            <Td>{val.nama_karyawan_pengaju ? val.nama_karyawan_pengaju : "-"}</Td>
                            <Td>{val.nama_jabatan_pengaju ? val.nama_jabatan_pengaju : "-"}</Td>
                            <TdFixed> {val.tgl_ppa ? DateConvert(new Date(val.tgl_ppa)).custom : "-"}</TdFixed>
                            <Td>{val.no_ppa ? val.no_ppa : "-"}</Td>
                            <Td>{val.item_sumber_daya ? val.item_sumber_daya : "-"}</Td>
                            <Td>{val.nama_unit_organisasi ? val.nama_unit_organisasi : "-"}</Td>
                            {/* <Td> <span className="text-capitalize">{val.keperluan ? val.keperluan : "-"}</span></Td>
                            <TdFixed> {val.tgl_pemakaian ? DateConvert(new Date(val.tgl_pemakaian)).custom : "-"} </TdFixed> */}
                            <TdFixed>{val.status_approval ? getStatusApprovalLabel(val.status_approval) : "PENDING"}</TdFixed>
                            <Td>
                                <div className="d-flex justify-content-center">
                                    <ActionButton
                                        size="sm"
                                        text="Lihat Detail"
                                        className="col"
                                        onClick={() => history.push(`/human-resource/approval/ppa/detail/${val.id_ppa}`, {
                                            no_ppa: val.no_ppa,
                                            tab: 'history'
                                        }
                                        )}
                                    />
                                </div>
                            </Td>
                        </Tr>
                    ))}
                </TBody>
            </Table>
        );

        return (
            <>
                <DataTable />
                {!searchConfig.status && (
                    <Pagination
                        dataLength={paginationConfig.dataLength}
                        dataNumber={
                            paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
                        }
                        dataPage={
                            paginationConfig.dataCount < paginationConfig.dataLength
                                ? paginationConfig.dataCount
                                : paginationConfig.page * paginationConfig.dataLength
                        }
                        dataCount={paginationConfig.dataCount}
                        currentPage={paginationConfig.page}
                        totalPage={paginationConfig.totalPage}
                        onPaginationChange={({ selected }) =>
                            setPaginationConfig({
                                ...paginationConfig,
                                page: selected + 1,
                            })
                        }
                        onDataLengthChange={(e) =>
                            setPaginationConfig({
                                ...paginationConfig,
                                page: 1,
                                dataLength: e.target.value,
                            })
                        }
                    />
                )}
            </>
        );
    };

    return (
        <CRUDLayout>
            {/* head section */}
            <CRUDLayout.Head>
                <CRUDLayout.HeadSearchSection>
                    <Row className="mb-2">
                        <Col md={8}>
                            <InputSearch
                                placeholder="Cari RAE"
                                value={searchConfig.key}
                                onChange={(e) => {
                                    const key = e.target.value;
                                    setSearchConfig({
                                        ...searchConfig,
                                        key: e.target.value,
                                    });
                                    setAlertConfig({
                                        show: key ? true : false,
                                        variant: "primary",
                                        text: "Hasil dari pencarian: " + key,
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                </CRUDLayout.HeadSearchSection>
                <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
            </CRUDLayout.Head>

            {/* alert */}
            <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
                showCloseButton={true}
                onClose={() =>
                    setAlertConfig({
                        ...alertConfig,
                        show: false,
                    })
                }
            />

            {/* content */}
            {isPageLoading ? (
                <DataStatus loading={true} text="Memuat data . . ." />
            ) : dataPPA ? (
                dataPPA.length > 0 ? (
                    <PageContent />
                ) : (
                    <DataStatus text="Tidak ada data" />
                )
            ) : (
                <DataStatus text="Data gagal dimuat" />
            )}
        </CRUDLayout>
    );
};

export default HistoryApprovalPPA;
