import { Col, Row, Card } from "react-bootstrap"
import { DateConvert } from "utilities"

const ListData = ({ title, text }) => (
	<div className='mb-2'>
		<small style={{ fontSize: 12 }} className="text-secondary">{title}</small>
		<div style={{ fontSize: 14 }}>{text ?? '-'}</div>
	</div>
)

const InfoComplimentSection = ({ className, data }) => {
	const InfoItemHorizontal = ({ label, text, width, minWidth = 120, style, className }) => (
		<div className={`d-flex align-items-top ${className}`} style={style}>
		  <div style={{ width: width ? width : 180, minWidth: minWidth, fontSize: 14 }}>{label}</div>
		  <div className="pl-3 pr-2" style={{ fontSize: 14 }}>:</div>
		  <div style={{ fontSize: 14 }}>{text}</div>
		</div>
	)
	return (
		<Card className={className}>
			<Card.Body>
				<Row>
				<Col>
					<InfoItemHorizontal width={200} label="Tgl. Compliment" text={data?.tgl_compliment ? DateConvert(new Date(data?.tgl_compliment)).detail : "-"} />
					<InfoItemHorizontal width={200} label="No. Compliment" text={data?.no_compliment ?? "-"} />
					<InfoItemHorizontal width={200} label="Customer" text={data?.customer ?? "-"} />
					<InfoItemHorizontal width={200} label="Petugas Pemberi Compliment" text={data?.petugas ?? "-"} />
				</Col>
				<Col>
					<InfoItemHorizontal width={200} label="Keterangan" text={data?.keterangan ?? "-"} />
				</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}

export default InfoComplimentSection
