import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Modal } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  DatePicker,
  TextArea,
  CRUDLayout,
} from "../../../components";
import { DateConvert, RupiahConvert } from "../../../utilities";
import { SuratJalanApi } from "../../../api";
import InfoSection from "./components/InfoSection";
import InfoDOSection from "./components/InfoDOSection";
import InfoSJSection from "./components/InfoSJSection";

const DetailSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const no_surat_jalan = state?.no_surat_jalan;
  const tab = state?.tab ?? "";
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataDeliveryOrder, setDataDeliveryOrder] = useState([]);
  console.log(dataDeliveryOrder)
  const [approveStatus, setApproveStatus] = useState("V");
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    SuratJalanApi.getSingle({ no_surat_jalan: no_surat_jalan })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : "V";
        setDataDeliveryOrder(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoSuratJalan = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const checkStatus = () => {
      if (modalConfig.type === "revise") {
        return "REV";
      }

      if (modalConfig.type === "approve") {
        return "APP";
      }

      if (modalConfig.type === "reject") {
        return "REJ";
      }
    };

    const finalValues = {
      no_transaksi: dataDeliveryOrder.detail.no_transaksi,
      status_approval: checkStatus(),
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataDeliveryOrder.detail.baseline,
    };

    SuratJalanApi.approve(finalValues)
      .then(() =>
        history.push("/human-resource/approval/surat-jalan", {
          alert: {
            show: true,
            variant: "primary",
            text: `Approval berhasil disimpan!`,
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Approval gagal disimpan!",
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle("Approval Surat Jalan");
    getInitialData();
    no_surat_jalan ? getInitialData() : whenNoSuratJalan();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  // const InfoSection = () => {
  //   const InfoItem = ({ title, value, isPaddingTop = false }) => (
  //     <tr>
  //       <td className="align-top" style={isPaddingTop ? { paddingTop: "10px" } : {}}>
  //         {title}
  //       </td>
  //       <td className="pl-4 pr-2 align-top">:</td>
  //       <td className="align-top">{value}</td>
  //     </tr>
  //   );

  //   return (
  //     <Row>
  //       <Col md>
  //         <table style={{ fontSize: "14px" }}>
  //           <tbody>
  //             <InfoItem
  //               title="No. Sales Order"
  //               value={
  //                 dataDeliveryOrder?.detail?.no_sales_order
  //                   ? dataDeliveryOrder?.detail?.no_sales_order
  //                   : "-"
  //               }
  //             />
  //             <InfoItem
  //               title="Tgl. Sales Order"
  //               value={
  //                 dataDeliveryOrder?.detail?.tgl_sales_order
  //                   ? DateConvert(new Date(dataDeliveryOrder?.detail?.tgl_sales_order)).detail
  //                   : "-"
  //               }
  //             />
  //             <InfoItem
  //               title="Customer"
  //               value={
  //                 dataDeliveryOrder?.detail?.nama_customer
  //                   ? dataDeliveryOrder?.detail?.nama_customer
  //                   : "-"
  //               }
  //             />
  //             <InfoItem
  //               title="Sales"
  //               value={
  //                 dataDeliveryOrder?.detail?.nama_sales
  //                   ? dataDeliveryOrder?.detail?.nama_sales
  //                   : "-"
  //               }
  //             />
  //             <InfoItem
  //               title="Tgl. Delivery Order"
  //               value={
  //                 dataDeliveryOrder?.detail?.tgl_delivery_order
  //                   ? DateConvert(new Date(dataDeliveryOrder?.detail?.tgl_delivery_order)).detail
  //                   : "-"
  //               }
  //             />
  //             <InfoItem
  //               title="No. Delivery Order"
  //               value={
  //                 dataDeliveryOrder?.detail?.no_delivery_order
  //                   ? dataDeliveryOrder?.detail?.no_delivery_order
  //                   : "-"
  //               }
  //             />
  //             <InfoItem
  //               title="Tgl. Surat Jalan"
  //               value={
  //                 dataDeliveryOrder?.detail?.tgl_surat_jalan
  //                   ? DateConvert(new Date(dataDeliveryOrder?.detail?.tgl_surat_jalan)).detail
  //                   : "-"
  //               }
  //             />
  //             <InfoItem
  //               title="No. Surat Jalan"
  //               value={
  //                 dataDeliveryOrder?.detail?.no_surat_jalan
  //                   ? dataDeliveryOrder?.detail?.no_surat_jalan
  //                   : "-"
  //               }
  //             />
  //             <InfoItem
  //               title="Gudang"
  //               value={
  //                 dataDeliveryOrder?.detail?.nama_gudang
  //                   ? dataDeliveryOrder?.detail?.nama_gudang
  //                   : "-"
  //               }
  //             />
  //             <InfoItem
  //               title="Petugas Pengiriman"
  //               value={
  //                 dataDeliveryOrder?.detail?.nama_sopir
  //                   ? dataDeliveryOrder?.detail?.nama_sopir
  //                   : "-"
  //               }
  //             />
  //             <InfoItem
  //               title="Armada Pengiriman"
  //               value={`${dataDeliveryOrder?.detail?.plat_nomor} - ${dataDeliveryOrder?.detail?.nama_item_aset}`}
  //             />
  //           </tbody>
  //         </table>
  //       </Col>
  //     </Row>
  //   );
  // };

  const TableDOSection = () => {
    const dataTableDO = dataDeliveryOrder?.detail?.detail ? dataDeliveryOrder.detail.detail : [];

    const Th = (props) => (
      <th
        {...props}
        className={`${props.className} align-middle`}
        style={{
          ...props.style,
          fontSize: "14px",
        }}
      >
        {props.children}
      </th>
    );

    const Td = (props) => (
      <td
        {...props}
        className={props.className}
        style={{
          ...props.style,
          fontSize: "14px",
        }}
      >
        {props.children}
      </td>
    );

    return (
      <>
        <Table bordered hovered size="sm">
          <thead className="text-center">
            <tr>
              <Th className="text-center" style={{ width: 30 }}>No</Th>
              <Th className="text-center" style={{ width: 100 }}>Kode Barang</Th>
              <Th className="text-center">Item Barang</Th>
              <Th className="text-center">Qty. Do</Th>
              <Th className="text-center">Qty. Surat Jalan</Th>
              <Th className="text-center">Satuan Jual</Th>
              <Th className="text-center">Qty. Satuan Pakai</Th>
              <Th className="text-center">Satuan Pakai</Th>
            </tr>
          </thead>
          <tbody>
            {dataTableDO.length > 0 ? (
              dataTableDO.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td className="px-1">{val.kode_barang}</Td>
                  <Td className="px-1">{val.nama_barang}</Td>
                  <Td className="px-1 text-right">{val.qty_do ?? 0}</Td>
                  <Td className="px-1 text-right">{val.qty ? parseFloat(val.qty) : 0}</Td>
                  <Td className="px-1">{val.nama_satuan}</Td>
                  <Td className="px-1 text-right">{parseFloat(val.qty_satuan_jual) ?? 0}</Td>
                  <Td className="px-1">{val.nama_satuan ?? "-"}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    );
  };
  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <h6>
            <b>
              {modalConfig.title === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.title}{" "}
              dengan catatan :
            </b>
          </h6>
          <span>{values.catatan}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type
            }
            variant={
              modalConfig.type === "approve"
                ? "success"
                : modalConfig.type === "revise"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };
  const FormCard = ({ formik }) => {
    const { values, errors, touched, dirty, validateForm, handleChange } = formik;
    const dataPengaju = dataDeliveryOrder?.approval ? dataDeliveryOrder.approval : [];

    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Detail Surat Jalan</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPengaju.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval == "VER" ? `Pemeriksa ${val.approval_level}` : "Pengesah"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
          {dataPengaju.length > 1 && <hr />}
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === "A" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: <span className="text-danger">Reject Detail Surat Jalan</span>,
                          });
                        }

                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"} Detail Surat Jalan
                              </span>
                            ),
                          });
                        }

                        validateForm();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: <span className="text-danger">Reject Detail Surat Jalan</span>,
                          });
                        }

                        validateForm();
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "revise",
                            title: <span className="text-warning">Revise Detail Surat Jalan</span>,
                          });
                        }

                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"} Detail Surat Jalan
                              </span>
                            ),
                          });
                        }

                        validateForm();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };
  const FormCardSection = ({ formik }) => {
    const { values, errors, touched, dirty, validateForm, handleChange } = formik;
    const dataPengaju = dataDeliveryOrder?.approval ? dataDeliveryOrder.approval : [];

    return (
      <>
        {tab !== "history" && (
          <div className="mt-4">
            <span style={{ fontSize: "14px" }}>
              <b>Catatan Approval Surat Jalan</b>
            </span>
          </div>
        )}
        {dataPengaju.length > 1 && <hr />}
        {tab !== "history" && (
          <div>
            <TextArea
              // label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "reject",
                          title: <span className="text-danger">Reject Detail Surat Jalan</span>,
                        });
                      }

                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "approve",
                          title: (
                            <span className="text-success">
                              {approveStatus === "A" ? "Approve" : "Verify"} Detail Surat Jalan
                            </span>
                          ),
                        });
                      }

                      validateForm();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "reject",
                          title: <span className="text-danger">Reject Detail Surat Jalan</span>,
                        });
                      }

                      validateForm();
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "revise",
                          title: <span className="text-warning">Revise Detail Surat Jalan</span>,
                        });
                      }

                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "approve",
                          title: (
                            <span className="text-success">
                              {approveStatus === "A" ? "Approve" : "Verify"} Detail Surat Jalan
                            </span>
                          ),
                        });
                      }

                      validateForm();
                    }}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  };
  const ListData = ({ title, text }) => (
    <div className="mb-2">
      <small style={{ fontSize: 12 }} className="text-secondary">
        {title}
      </small>
      <div style={{ fontSize: 14 }}>{text ?? "-"}</div>
    </div>
  );
  const CatatanApproval = () => {
    const InfoItem = ({ labelHeader, karyawanName, labelCatatan, catatanValue }) => (
      <>
        <small>{labelHeader}</small>
        <p>
          <b>{karyawanName}</b>
        </p>
        <small>{labelCatatan}</small>
        <p>
          <b>{catatanValue}</b>
        </p>
      </>
    );

    return (
      <>
        <div className="mt-4">
          <span style={{ fontSize: "14px" }}>
            <b>Catatan Approval Surat Jalan</b>
          </span>
        </div>
        <Card>
          <Card.Body>
            <Row>
              {dataDeliveryOrder?.detail?.stakeholder === undefined ? (
                <Col className="text-center">
                  <small>Memuat Data...</small>
                </Col>
              ) : (
                dataDeliveryOrder?.detail?.stakeholder.map((val, index) => (
                  <>
                    <Col md={3} key={index}>
                      <InfoItem
                        labelHeader={val.status_approval === "APP"
                          ? "Pengesah"
                          : index === 0
                          // ? "Pembuat"
                          ? "Pengaju"
                          : `Pemeriksa ${
                              val.approval_level !== "0"
                                ? val.approval_level
                                : ""
                            }`
                        }
                        karyawanName={val.nama_karyawan ?? "-"}
                        labelCatatan={
                          val.status_approval === "APP"
                            ? "Catatan Pengesah"
                            : index !== 0
                            ? `Catatan Pemeriksa ${
                                val.approval_level !== "0"
                                  ? val.approval_level
                                  : ""
                              }`
                            : ""
                        }
                        catatanValue={index !== 0 ? val.catatan : ""}
                      />
                    </Col>
                  </>
                ))
              )}
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <div className="font-weight-bold mb-2">Detail Data Surat Jalan</div>
      <Card>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />

          {isPageLoading 
            ? (<DataStatus loading={true} text="Memuat data . . ." />) 
            : isFetchingFailed 
              ? (<DataStatus text="Data gagal dimuat!" />) 
              : (
                  <>
                    <InfoSection data={dataDeliveryOrder?.detail} />
                    <InfoDOSection data={dataDeliveryOrder?.detail} />
                    <InfoSJSection data={dataDeliveryOrder?.detail} />
                    <hr />
                    <TableDOSection />
                    <ListData
                      title="Catatan Delivery Order"
                      text={dataDeliveryOrder?.detail?.catatan_delivery_order ?? "-"}
                    />
                    <CatatanApproval />
                    {!isFetchingFailed && !isPageLoading && (
                      <Formik
                        initialValues={{ catatan: "" }}
                        validationSchema={Yup.object().shape({catatan: Yup.string().required("Masukan catatan")})}
                        onSubmit={formSubmitHandler}
                      >
                        {(formik) => (
                          <>
                            {/* <FormCard formik={formik} /> */}
                            <FormCardSection formik={formik} />
                            <ModalSection formik={formik} />
                          </>
                        )}
                      </Formik>
                    )}
                  </>
          )}
        </Card.Body>
      </Card>
    </CRUDLayout>
  );
};

export default DetailSuratJalan;
